<template>
    <div class="row">
        <div class="col-md-6">

            <div class="d-flex justify-content-between mt-1 sticky-top bg-dark p-1 border-bottom">
                <button type="button"
                        class="btn btn-secondary"
                        @click="OnCancel">
                    Cancel
                </button>

                <button type="button"
                        class="btn btn-danger"
                        @click="onDelete">
                    Delete
                </button>

                <div class="btn-group">
                    <button type="button"
                            class="btn btn-primary"
                            @click="onSave">
                        Save
                    </button>
                </div>
            </div>

            <div class="mt-1">
                <label>
                    Title
                </label>
                <input  :disabled="submitting" 
                        :placeholder="'Session Title'"
                        class="mb-2 form-control"
                        v-model="editSession.Name" />
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <label>
                            Code (DO NOT CHANGE)
                        </label>
                        <input  :disabled="submitting" 
                                :placeholder="'Session Code'"
                                class="mb-2 form-control"
                                v-model="editSession.Code" />
                    </div>
                </div>
                <div class="col">
                    <div>
                        <label>
                            Category
                        </label>
                        <select class="form-select" v-model="editSession.Category">
                            <option selected disabled hidden :value="null">(Please Select...)</option>
                            <option v-for="category in show.SessionCategories" :value="category">
                                {{category.Name}}
                            </option>
                        </select>

                        <label class="ps-3 form-check-label">
                            <input type="checkbox"
                                    v-model="editSession.GlobalFavorite">
                            Global Favorite
                        </label>

                    </div>
                </div>
            </div>
            
            <!--<div>
                <label>
                    Capacity
                </label>
                <input type="number"
                    class="form-control"
                    min="0"
                    step="1"
                    v-if="editSession.RegistrationLimit > 0"
                    v-model="editSession.RegistrationLimit">
                <select v-else
                        class="form-select"
                        v-model="editSession.RegistrationLimit">
                    <option :value="0">
                        No Limit
                    </option>
                    <option :value="1">
                        Set Capacity...
                    </option>
                </select>
            </div>-->

            <div class="row mt-1">
            
                <div class="col">
                    <ctk-date-time-picker v-model="startTime"
                                        format="YYYY-MM-DDTHH:mmZ"
                                        minuteInterval="1"
                                        :disabled="submitting">
                    </ctk-date-time-picker>

                    {{getDuration}} minutes - {{editSession.SessionStartDateTime | moment('YYYY-MM-DD hh:mm A')}} to {{editSession.SessionEndDateTime | moment('hh:mm A')}}
                </div>
                <div class="col text-center">
                    <div class="input-group">
                        <input v-model="endTimeDuration"
                            type="number"
                            class="form-control"
                            min="0"
                            max="10000">
                        <button class="btn btn-outline-primary"
                                @click="endTimeDuration = 15"
                                type="button">
                            15
                        </button>
                        <button class="btn btn-outline-primary"
                                @click="endTimeDuration = 30"
                                type="button">
                            30
                        </button>
                        <button class="btn btn-outline-primary"
                                @click="endTimeDuration = 60"
                                type="button">
                            60
                        </button>
                        <button class="btn btn-outline-primary"
                                @click="endTimeDuration = 90"
                                type="button">
                            90
                        </button>
                    </div>
                    <p class="sneaky-hide">
                        {{editSession.SessionStartDateTime}} to {{editSession.SessionEndDateTime}}
                    </p>                
                </div>
            </div>

            <div class="row mt-1">
                <div class="col">
                    <div class="form-floating">
                        <input v-model="editSession.Location" 
                            placeholder="Location"
                            class="form-control">
                        <label>Location</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-floating">
                        <input v-model="editSession.Headlines" 
                            placeholder="Headline"
                            class="form-control">
                        <label>Headline</label>
                    </div>
                </div>
            </div>

            <div class="form-floating mt-1">
                <input v-model="editSession.CustomDeepLinkUrl"
                    placeholder="Custom Deep Link URL"
                    class="form-control">
                <label>Custom Deep Link URL</label>
            </div>

            <hr>

            <div class="row">
                <div class="col">
                    <div class="form-floating">
                        <select v-model="editSession.DiscussionCode" class="form-select">
                            <option :value="''">(Please Select...)</option>
                            <option v-for="destination in discussions" :value="destination.Code" :key="destination.Code">
                                {{destination.Name}}
                            </option>
                        </select>
                        <label>
                            Linked Discussion Area
                        </label>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="d-flex h-100 btn-group">
                        <button class="btn btn-outline-primary"
                                type="button"
                                @click="addDiscussionForSession">
                            Add
                        </button>
                    </div>  
                </div>
            </div>        


            <hr>

            <div class="mb-2 btn-group btn-group-sm">
                <button type="button" @click.prevent="onAddContactInfo" class="btn btn-primary">
                    <i class="bi bi-plus-square ps-1 me-1"></i>Add...
                </button>
                <template v-if="show.IsPublicShow">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle"
                                type="button"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown">
                            Templates  
                        </button>
                        <ul class="dropdown-menu">
                            <li class="dropdown-item user-select-none pe-auto"
                                v-for="template in newItemTemplates"
                                :key="template.TemplateName"
                                @click="addTemplatedItem(template)">
                                {{ template.TemplateName }}
                            </li>
                        </ul>
                    </div>
                </template>
                <button type="button" @click.prevent="onStartAddAttendee" class="btn btn-primary">
                    <i class="bi bi-person ps-1 me-1"></i>Speaker...
                </button>
            </div>

            <vue-slicklist :useDragHandle="true" 
                        v-model="editSession.ContactInfo.ContactInfo" 
                        lockAxis="y"
                        v-if="tractusLinkDestinations">
                <vue-slickitem class="row" 
                                v-for="(contactInfo, i) in editSession.ContactInfo.ContactInfo" 
                                :key="i" 
                                :index="i">
                    <i class="col-auto bi-grip-vertical" v-handle></i>
                    <div class="col">
                        <contact-info-editor :contactInfo="contactInfo"
                                            :selectTypes="contactInfoTypes"
                                            :submitting="submitting"
                                            :show="show"
                                            v-on:delete-contact-info="onDeleteContactInfo(editSession.ContactInfo.ContactInfo, contactInfo)"
                                            :isContact="true"
                                            :destinations="tractusLinkDestinations"
                                            :entity="editSession"
                                            :entityCode="editSession.Code"
                                            :entityTypeCode="'SESSION'"
                                            ref="contactInfoEditor">
                        </contact-info-editor>
                    </div>
                </vue-slickitem>
            </vue-slicklist>
            <loading v-else />
            <div class="mb-2">
                <button type="button" @click.prevent="onAddContactInfo" class="btn btn-primary">
                    <i class="bi bi-plus-square ps-1"></i>&nbsp;Add Contact Info/Social Media
                </button>
                <button type="button" @click.prevent="onStartAddAttendee" class="btn btn-primary">
                    <i class="bi bi-plus-square ps-1"></i>&nbsp;Add Speaker (Attendee)
                </button>
            </div>

            <hr>



            <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
                Oops! It looks like something happened. Please double-check and try again.
                <ul>
                    <li v-for="error in validateErrors">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <div class="modal fade"
                ref="addAttendeeModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Create Speaker</h5>
                            <button type="button" class="btn-close"
                                    @click="hideModal">
                            </button>
                        </div>
                        <div class="modal-body">
                            <attendee-editor v-if="editAttendee"
                                            :show="show"
                                            :attendee="editAttendee"
                                            :doNotSave="false"
                                            @saved="onFinishAddAttendee"
                                            @cancel="onFinishAddAttendee(null)"
                                            :destinations="tractusLinkDestinations">
                            </attendee-editor>                

                        </div>
                    </div>
                </div>
            </div> 
        </div>
        <div class="col-md-6">
            <media-information v-if="editSession.PrimaryMedia"
                            :mediaInformation.sync="editSession.PrimaryMedia"
                            :show="show">
            </media-information>
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import CommonTypes from '../common.types'
import moment from 'moment'
import ContactInfo from '../../models/ContactInfo'
import AttendeeAdditionalInfo from '../../models/AttendeeAdditionalInfo'
import Attendee from '../../models/Attendee'
import { Modal } from 'bootstrap'
import DateTimePicker from '../date-time-picker.vue'

export default {

    props: ['session','show'],

  
    mixins: [
        Token,
        Common,
        CommonTypes
    ],

    components: {
        DateTimePicker
    },

    watch: {
        'editSession.Code'(newVal, oldVal) {

            if(!newVal || newVal.length < 8) {
                return;
            }

            if(!this.editSession) {
                return;
            }

            if(!this.editSession._IsNew) {
                return;
            }

            if(newVal.length == 8
                && (newVal.substr(0, 2) == 'EN' || newVal.substr(0, 2) == 'FR')) {
                
                let month = newVal.substr(2, 2);
                let day = newVal.substr(4, 2);
                let year = newVal.substr(6, 2);
                console.log('Here we go!');
                try {
                    month = parseInt(month) - 1;
                    year = parseInt(year) + 2000;
                    day = parseInt(day);

                    let newDate = new Date(year, month, day, 12, 0, 0, 0);
                    console.log(newDate)
                    this.startTime = newDate
                } catch {

                }
            }
        
            console.log(newVal, oldVal)
        }
    },

    data() {
        return {
            speakerInfo: {},
            editSession: null,
            currAttendee: null,
            validCode: true,
            validDate: true,
            discussions:[],
            editAttendee: null,
            addAttendeeModal: null,
            newItemTemplates: [
                {
                    Type: 'ICSDetails',
                    TemplateName: 'ICS Generic - EN', 
                    Details: '<a href="{{Url}}">Click here to watch the live webinar ({{Url}})</a>'
                },
                {
                    Type: 'ICSDetails',
                    TemplateName: 'ICS Generic - FR', 
                    Details: '<a href="{{Url}}">Cliquez ici pour rejoindre ({{Url}})</a>'
                },
                {
                    Type: 'GoogleCalDetails',
                    TemplateName: 'Google Cal Generic - EN', 
                    Details: '<a href="{{Url}}">Click here to watch the live webinar ({{Url}})</a>'
                },
                {
                    Type: 'GoogleCalDetails',
                    TemplateName: 'Google Cal Generic - FR', 
                    Details: '<a href="{{Url}}">Cliquez ici pour rejoindre ({{Url}})</a>'
                },

            ]
        }
    },

    async created() {
        this.editSession = this.cloneObject(this.session);
        await this.getTractusLinkDestinationsAsync(this.show.Code);
        
        if(!this.editSession.GlobalFavorite) {
            Vue.set(this.editSession, 'GlobalFavorite', false);
        }

        if(!this.editSession.Code) {
        }

        if(!this.editSession.DiscussionWebinarStatus) {
            Vue.set(this.editSession, 'DiscussionWebinarStatus', null);
        }

        if(!this.editSession.CustomDeepLinkUrl) {
            Vue.set(this.editSession, 'CustomDeepLinkUrl', null);
        }

        if(!this.editSession.RegistrationLimit) {
            Vue.set(this.editSession, 'RegistrationLimit', 0);
        }

        if(typeof this.editSession.PrimaryMedia === 'undefined'
            || this.editSession.PrimaryMedia == null){
            Vue.set(this.editSession, "PrimaryMedia", {
                Code: this.createUniqueCode(),
                Uri: "",
                MediaType: "Video",
                AutoPlay: false,
                ThumbnailUri: "",
                Title: "",
                Description: "",
                PlayAfterExit: false
            });
        }

        if(this.editSession.Category != null && this.show.SessionCategories != null) {
            for(var i = 0; i < this.show.SessionCategories.length; i++) {
                var category = this.show.SessionCategories[i];
                if(category.Code === this.editSession.Category.Code) {
                    this.editSession.Category = category;
                    break;
                }
            }
        }        

        this.getDiscussions();

        this.currContactInfo = new ContactInfo();
    },

    computed: {
        getDuration() {
            if(!this.editSession) {
                return;
            }

            let duration = moment.duration(moment(this.editSession.SessionEndDateTime).diff(moment(this.editSession.SessionStartDateTime)));
            
            return duration.asMinutes();
        },

        startTime: {
            get() {
                return this.editSession.SessionStartDateTime;
            },
            set(newValue) {
                let oldDuration = this.getDuration;
                console.log(`OLD: ${oldDuration}`)
                this.editSession.SessionStartDateTime = moment(newValue).utc(false).toISOString();

                this.endTimeDuration = oldDuration;
            },
        },

        endTimeDuration: {
            get() {
                return this.getDuration;
            },
            set(newValue, oldValue) {
                let endDate = moment(this.editSession.SessionStartDateTime).add('minutes', newValue).utc(false).toISOString();

                this.editSession.SessionEndDateTime = endDate;

            },
        }
    },

    methods: {
        async addDiscussionForSession() {
            let discussion = {
                Id: '0',
                ShowCode: this.show.Code,
                ParticipantRoles: {},
                TypeCode: "LiveQa",
                Name: `${this.editSession.Name}`,
                AllowAnonymous: true,
                RequireApproval: false,
                AllowPost: true,
                InviteOnly: false,
                NotifyParticipants: false,
                AllowCall: false,
                WebinarFormat: true,
                MaxCallers: 50,
                VisibleToCategories: []
            };

            Vue.set(this.editSession, 'DiscussionWebinarStatus', 'Private');

            await this.saveChangesToDiscussionAsync(discussion);
        },

        async saveChangesToDiscussionAsync(discussion) {
            try {
                let result = await this.tryPost("/api/discussion", JSON.stringify(discussion), 'application/json');
                await this.getDiscussions();

                Vue.nextTick(() => {
                    Vue.set(this.editSession, 'DiscussionCode', result.Result.Code);
                })
            } catch(ex) {
                console.error(ex);
                alert("Could not save discussion.");
            }
        },

        async addTemplatedItem(template) {
            let toAdd = new ContactInfo();
            toAdd.Type = template.Type;

            if(toAdd.Type == 'GoogleCalDetails' || toAdd.Type == 'ICSDetails') {

                let targetUrl = `${process.env.VUE_APP_WEBINAR_APP_URL}/watch/${this.show.Code}/${this.editSession.Code}`;

                let shortLink = {
                    ShowCode: this.show.Code.toLowerCase(),
                    UrlCode: this.editSession.Code.toLowerCase(),
                    Description: `Player Page - ${this.editSession.Name}`,
                    TargetUrl: targetUrl
                }

                await this.tryPost('/api/shorturl', JSON.stringify([shortLink]), 'application/json');
                let shortLinkDestination = `https://u.tractus.ca/${this.show.Code.toLowerCase()}/${this.editSession.Code.toLowerCase()}`;

                toAdd.DisplayText = this.editSession.Name;
                toAdd.Details = template.Details.replace(/\{\{Url\}\}/g, shortLinkDestination);
            }

            this.editSession.ContactInfo.ContactInfo.push(toAdd);
        },

        hideModal(){
            this.addAttendeeModal.hide();
        },
        onStartAddAttendee() {
            let newAttendee = new Attendee();
            newAttendee.ContactInfo = new AttendeeAdditionalInfo();
            newAttendee.Id = "0";
            newAttendee.Code = "";
            newAttendee.Show = {
                Code: this.show.Code
            };
            
            this.editAttendee = newAttendee;

            this.addAttendeeModal = new Modal(this.$refs.addAttendeeModal);

            this.addAttendeeModal.show();
        },
        
        async onFinishAddAttendee(attendee) {
            this.addAttendeeModal.hide();
            if(!attendee) {
                return;
            }

            await this.getTractusLinkDestinationsAsync(this.show.Code);
            
            let link = this.tractusLinkDestinations['ATTENDEES'].find(x => x.Code == attendee.Code).TractusLink;

            let newContactInfo = this.onAddContactInfo(link, null, 'TractusLink');
            newContactInfo.Value = link;
            newContactInfo.DisplayText = [attendee.DisplayName, (attendee.ContactInfo.Title ? ` - ${attendee.ContactInfo.Title}` : '')].join('');
            this.addAttendeeModal.hide();

            this.editAttendee = null;
        },

        //also used on poll.transfer.to.page
        onAddContactInfo(value, property, type) {
            var toAdd = {
                Details: "",
                DisplayText: "",
                Type: "",
                Value: '',
            };

            if(!this.editSession.ContactInfo.ContactInfo) {
                Vue.set(this.editSession.ContactInfo, 'ContactInfo', []);
            } 

            if(value && property) {
                value = value.trim();
                toAdd[property] = value;
                toAdd.Type = 'Website';
            }


            if(type) {
                toAdd.Type = type;

                if(type == 'Email' && value) {
                    toAdd.DisplayText = value;
                }
            }

            this.editSession.ContactInfo.ContactInfo.push(toAdd);
            this.$emit('update:session', this.editSession);

            return toAdd;
        },

        //used on poll.transfer.to.page
        flashProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editSession, propertyName, value);
            this.$emit('update:session', this.editSession);
        },

        //used on poll.transfer.to.page
        flashMediaProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editSession.PrimaryMedia, propertyName, value);
            this.$emit('update:session', this.editSession);
        },

        //used on poll.transfer.to.page
        flashContactInfoProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editSession.ContactInfo, propertyName, value);
            this.$emit('update:session', this.editSession);
        },

        //used on poll.transfer.to.page
        flashContactInfoItemProperty(value, index, propertyName) {
            value = value.trim();
            Vue.set(this.editSession.ContactInfo.ContactInfo[index], propertyName, value);
            this.$emit('update:session', this.editSession);
        },

        onDeleteContactInfo(deleteFrom,toBeDeleted) {
            deleteFrom.splice(deleteFrom.indexOf(toBeDeleted), 1);
        },

        validateForm: function() {
            this.validateErrors = [];
            this.validCode=true;
            this.validDate = true;

             if(this.editSession.PrimaryMedia.Uri && this.editSession.PrimaryMedia.Uri != ""){
                if(!this.editSession.PrimaryMedia.Uri.replace(/\s/g, '').length){
                    this.validateErrors.push("Uri cannot contain only spaces.");
                } 
                if(this.editSession.PrimaryMedia.Title && !this.editSession.PrimaryMedia.Title.replace(/\s/g, '').length){
                    this.validateErrors.push("Video Title cannot contain only spaces.");
                }
            }


            //Check if session code is unique 
            if(this.editSession.Code && this.editSession.Id === '0'){
               
                for(var i = 0; i < this.show.SessionCategories.length; i++){

                    var key = this.show.SessionCategories[i].Code
               
                    for(var j = 0; j < this.show.Sessions[key].length; j++){
                        
                        if(this.editSession.Code.toUpperCase() === this.show.Sessions[key][j].Code.toUpperCase()){
                            this.validCode = false;
                        }
                    }
                }
            }
            if(this.editSession.SessionStartDateTime && this.editSession.SessionEndDateTime){
                if(moment(this.editSession.SessionEndDateTime).isBefore(moment(this.editSession.SessionStartDateTime))){
                    this.validDate= false;
                }
            }
            
            var str = this.editSession.Name;
            if (this.editSession.Name && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Session Name cannot be only spaces.");
            }
            if(!this.editSession.Name) {
                this.validateErrors.push("Session Name cannot be blank.");
            }
            var letters = /^[0-9a-zA-Z_]+$/;
            if(this.editSession.Code && !this.editSession.Code.match(letters)){
                this.validateErrors.push("Session Code can only have numbers and letters.");
            }
            if(!this.editSession.Code) {
                this.validateErrors.push("Session Code cannot be blank.");
            }
            if(!this.editSession.SessionStartDateTime && !this.editSession.SessionEndDateTime) {
                this.validateErrors.push("Start Time/Date and End Time/Date cannot be blank.");
            }
            if(!this.editSession.SessionStartDateTime && this.editSession.SessionEndDateTime) {
                this.validateErrors.push("Start Time/Date cannot be blank.");
            }
            if(this.editSession.SessionStartDateTime && !this.editSession.SessionEndDateTime) {
                this.validateErrors.push("End Time/Date cannot be blank.");
            }
            if(!this.validDate){
                this.validateErrors.push("Session End Date/Time must be after the Start Date/Time.");
            }
            if(!this.editSession.Category.Code){
                this.validateErrors.push("No Session Category has been selected.");
            }
        
            return this.validateErrors.length === 0;
        },  


        async getDiscussions(){
            try{
                let r = await this.tryGet(`/api/discussions/show/${this.show.Code}`);
                this.discussions = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
        },

        async onDelete() {

            if(!confirm("Are you sure you want to delete this session? This cannot be undone.")) {
                return;
            }

            this.submitting = true;
            
            this.editSession.Code = this.editSession.Code.toUpperCase();
            this.editSession.Show = {
                Id: this.show.Id,
                Code: this.show.Code
            };

            try{
                let r = await this.tryDelete(`/api/session/${this.show.Code}/${this.editSession.Code}`);
                
                if(r.Errors.length === 0) {
                    this.$emit('deleted', this.session);
                }
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },

        async onSave() {
            this.submitting = true;

            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            if(this.editSession.SessionStartDateTime){
                this.editSession.SessionStartDateTime = moment(this.editSession.SessionStartDateTime).utc().format();
            }
            
            if(this.editSession.SessionEndDateTime){
                this.editSession.SessionEndDateTime = moment(this.editSession.SessionEndDateTime).utc().format();
            }

            this.editSession.SpeakerInfo = [];
            for(let code in this.speakerInfo) {
                if(!this.speakerInfo[code]) {
                    continue;
                }

                this.editSession.SpeakerInfo.push({
                    SpeakerCode: code,
                    Title: this.speakerInfo[code].Title,
                    Description: this.speakerInfo[code].Description
                });
            }
            
            this.editSession.Show = {
              Code: this.show.Code
            };

            this.editSession.Code = this.editSession.Code.toUpperCase();
            try{
                let r = await this.tryPost('/api/session', JSON.stringify(this.editSession), 'application/json');
                    
                if(r.Errors && r.Errors.length == 0){
                    this.$emit('saved', this.show, r.Result);
                }
                    
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
        },
        OnCancel(){
            this.submitting = false;
            this.$emit('cancel');
        },
    }
    
}
</script>