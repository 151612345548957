<template>
    <div class="container-fluid" v-if="mediaInformation">
        <div class="row">
            <div class="col">
                <h5>Profile Media</h5>

                <label>
                    Poster Frame/Cover Photo
                </label>

                <div class="input-group mt-2 mb-2">
                    <input  :disabled="submitting" 
                            class="form-control form-control-sm"
                            v-model="editMediaInformation.ThumbnailUri" />
                    <button type="button" class="btn btn-primary btn-sm" @click="onChoose">
                        Select...
                    </button>
                    <button type="button" class="btn btn-warning btn-sm" @click="editMediaInformation.ThumbnailUri = null">
                        Clear
                    </button>
                </div>

                <file-picker ref="picker" 
                        @fileSelected="onFileSelected" 
                        :showCode="show.Code"
                        type="image"
                        :imagePath="editMediaInformation.ThumbnailUri"/>

                <img class="img-fluid" 
                        :src="editMediaInformation.ThumbnailUri"
                        @click="onChoose" />

                <div class="form-floating mt-2">
                    <select class="form-select" v-model="editMediaInformation.MediaType">
                        <option selected disabled hidden :value="null">(Please Select...)</option>
                        <option v-for="type in mediaType" :value="type.Type">
                            {{type.Name}}
                        </option>
                    </select>    
                    <label>Type</label>
                </div>

            </div>
        </div>
        <div class="row">

            <div class="col" v-if="editMediaInformation.MediaType == 'MuxLive'">
                <div class="form-floating mt-2">
                    <select class="form-select" v-model="editMediaInformation.Uri">
                        <option selected disabled hidden :value="null">(Please Select Live Stream...)</option>
                        <option v-for="video in streams" :value="video.PlaybackKey">
                            {{video.Name}}
                        </option>
                    </select>    
                    <label>Live Stream Endpoint</label>
                </div>
            </div>
            <div class="col" v-else-if="editMediaInformation.MediaType == 'MuxAsset'">
                <div class="form-floating mt-2">
                    <select class="form-select" v-model="editMediaInformation.Uri">
                        <option selected disabled hidden :value="null">(Please Select Asset...)</option>
                        <option v-for="video in assets" :value="video.PlaybackId">
                            {{video.Name}}
                        </option>
                    </select>    
                    <label>Video</label>
                </div>

            </div>
            <div class="col">
                <div class="form-floating mt-2">
                    <input  :disabled="submitting" 
                        class="mb-2 form-control"
                        v-model="editMediaInformation.Uri" />
                    <label>URL/Path/Playback Key</label>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

export default{
    props: ['show','mediaInformation'],
    mixins: [
        Token, 
        Common
    ],

    watch: {
        'mediaInformation.Uri': function(newVal, oldVal) {
            if(newVal.startsWith('https://www.youtube.com/watch?')) {
                //https://www.youtube.com/embed/JL9w0jvWS2g?autoplay=1

                let embedPath = newVal.replace('/watch?v=', '/embed/');

                // if(embedPath.indexOf('?') == -1) {
                //     embedPath += '?autoplay=1';
                // } else {
                //     embedPath += '&autoplay=1';
                // }

                this.mediaInformation.Uri = embedPath;

                this.mediaInformation.MediaType = 'LiveWebSource';
                this.mediaInformation.AutoPlay = true;
            } else if (newVal.startsWith("https://youtu.be/")) {
                // https://youtu.be/X-SMvEmL1Xg
                let embedPath = newVal.replace('https://youtu.be/', 'https://www.youtube.com/embed/');

                // if(embedPath.indexOf('?') == -1) {
                //     embedPath += '?autoplay=1';
                // } else {
                //     embedPath += '&autoplay=1';
                // }

                this.mediaInformation.Uri = embedPath;

                this.mediaInformation.MediaType = 'LiveWebSource';
                this.mediaInformation.AutoPlay = true;
            } else if (newVal.startsWith('https://www.twitch.tv/')) {
                let channelName = newVal.split('/').pop();

                let embedPath = `https://player.twitch.tv/?volume=0.5&!muted&channel=${channelName}`;

                this.mediaInformation.Uri = embedPath;
                this.mediaInformation.MediaType = 'LiveWebSource';
                this.mediaInformation.AutoPlay = true;
            }
        },
        'editMediaInformation.MediaType':function(newVal, oldVal){
            console.log("New media type: ", newVal)
            if(newVal == 'MuxAsset'){
                this.loadAssets();
            } else if(newVal == 'MuxLive') {
                this.loadLiveStreams();
            }
        }
    },

    data() {

        return {
            editMediaInformation: null,
            mediaType: [
                {Type:null, Name:"None"},
                {Type:"LiveWebSource", Name:"Web Source (YouTube, Twitch, etc.)"},
                {Type:"HlsSource", Name:"HLS (M3U8) Source"},
                {Type:"MuxLive", Name:"Mux Live Stream"},
                {Type:"MuxAsset", Name:"Mux Recording"},
            ],
            streams: [],
            assets: [],

            // editShow:null,
            // editvendorCategories: null,
            // validCode: true
        }
    },

    created: function() {
        this.editMediaInformation = this.mediaInformation;
        if(this.editMediaInformation.MediaType == "MuxAsset") {
            this.loadAssets();
        }

        if(this.editMediaInformation.MediaType == "MuxLiveStream") {
            this.loadLiveStreams();
        }
    },

    methods: {
        async loadAssets(){
            this.assets = [];

            let url = `/api/mux/assets/${this.show.Code}`;
            let result = await this.tryGet(url);

            this.assets = result.Result.sort((a, b) => a.Name.localeCompare(b.Name));
        },

        async loadLiveStreams() {
            this.streams = [];

            let url = `/api/mux/livestreams/${this.show.Code}`;
            let result = await this.tryGet(url);

            this.streams = result.Result.sort((a, b) => a.Name.localeCompare(b.Name));
            
        },

        onFileSelected(fileName){
            this.editMediaInformation.ThumbnailUri = fileName;
            // this.editAttendee.ContactInfo.PhotoUrl = fileName;
        },
        onChoose(){
            // console.log(this.show);
            this.$refs.picker.openPicker();
        },
    }

}
</script>