<template>
    <loading v-if="submitting">

    </loading>
    <div v-else-if="requiresPassword"
         class="d-flex align-items-center justify-content-center">
        <div class="border p-2 text-center" style="width: 250px; max-width: 250px;">
            <form @submit.prevent="build">
                <h4>View Results</h4>

                <div class="form-input-group mb-2">
                    <input type="password"
                        v-model="password"
                        class="form-control form-control-sm"
                        placeholder="Password">
                </div>

                <div v-if="wrongPassword"
                    class="alert alert-danger">
                    Incorrect password entered.
                </div>

                <button type="button"
                        @click="build"
                        class="btn btn-primary">
                    Open    
                </button>
            </form>
        </div>
    </div>
    <div v-else-if="!submitting && !requiresPassword && poll"
         class="container-fluid">
        <vue-title :title="poll.Name"></vue-title>

        <div class="fixed-top bg-body-tertiary poll-results-header border-bottom">
            <h5 class="mt-2 mb-2 text-center">{{ poll.Name }} ({{ poll.Responses.length }} Responses)</h5>

            <div class="row justify-content-center">
                <div class="col-auto">
                    <div class="input-group">
                        <button class="btn btn-sm btn-success"
                                type="button"
                                @click.prevent="changePage(-1)">
                            <i class="bi bi-chevron-left"></i>                                
                        </button>

                        <span class="form-text ms-2">
                            {{ page + 1 }} / {{ totalPages }} ({{filteredResults.length}})
                        </span>

                        <select class="form-select form-select-sm ms-2"
                                v-model="showTop">
                            <option v-for="filter in showTopFilters"
                                    :value="filter.Amount">
                                {{filter.Name}}
                            </option>
                        </select>

                        <span class="form-text ms-2 me-2">
                            Refreshed At: {{ lastRefreshed | moment("L LT") }}
                        </span>
                        <button class="btn btn-primary btn-sm" @click="build">
                            <i class="bi bi-arrow-clockwise"></i> Refresh
                        </button>
                        <button type="button" 
                                class="btn btn-success btn-sm"
                                @click="downloadRegListToExcel">
                            <i class="bi bi-cloud-download-fill"></i> Download
                        </button>
                        <button class="btn btn-sm btn-success"
                                type="button"
                                @click.prevent="changePage(1)">
                            <i class="bi bi-chevron-right"></i>                                
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="row analytics-area">
            <div :class="{'col-md-6': hasValidFilters, 'col-12': !hasValidFilters}">
                <h5># Responses by Date</h5>
                <LineChart :data="answerRateData"></LineChart>

                <div class="table-responsive"
                     v-if="answerRateData && answerRateData.datasets && answerRateData.datasets.length > 0">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th v-for="date in answerRateData.labels">
                                    {{date}}
                                </th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-for="data in answerRateData.datasets[0].data">
                                    {{data}}
                                </td>
                                <td class="fw-bold">
                                    {{answerRateData.datasets[0].data.reduce((ac, cv)=>ac+cv, 0)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6" v-if="hasValidFilters">
                <h5>
                    <div class="dropdown d-inline-block">
                    <button class="btn btn-sm me-3 btn-outline-primary d-inline-block"
                            id="summaryGraphDropdownBtn"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown">
                        <i class="bi bi-funnel"></i>
                        
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="filter in filters"
                            class="dropdown-item user-select-none p-auto"
                            @click="summaryGraphFilter = filter.Code">
                            {{filter.Name}}
                        </li>
                    </ul>
                </div>{{filters[summaryGraphFilter].Name}}...</h5>
                <BarChart v-if="summaryGraphFilter" :data="summaryGraphData"></BarChart>
                
                <div class="table-responsive"
                     v-if="answerRateData && answerRateData.datasets && answerRateData.datasets.length > 0">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <tr>
                                <th v-for="date in summaryGraphData.labels">
                                    {{date}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-for="data in summaryGraphData.datasets[0].data">
                                    {{data}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="poll-results-detail-table">
            <table class="table table-hover table-striped tPollInviteListable-sm response-table">
                <thead class="atn-header border-bottom border-dark bg-body-tertiary sticky-top">
                    <tr>
                        <th v-for="question in tableQuestions" class="">
                            <div v-if="question.QuestionType.indexOf('MultipleChoice') != -1 || question.QuestionType == 'INTERNAL'"
                                 class="h-100 dropdown">
                                <button :id="`H_${question.Code}`"
                                    data-bs-auto-close="outside"
                                    data-bs-toggle="dropdown"
                                    :class="[filters[question.Code].SelectedOption ? 'active' : '']"
                                    class="h-100 btn text-white btn-outline-secondary btn-sm d-block w-100">

                                    <i class="bi bi-funnel-fill"
                                    v-if="filters[question.Code].SelectedOption"></i>
                                    <i class="bi bi-funnel"
                                    v-else></i>{{ question.Name }}
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="option in filters[question.Code].Options"
                                        class="dropdown-item user-select-none pe-auto"
                                        :class="[filters[question.Code] && filters[question.Code].SelectedOption == option ? 'active' : '']"
                                        @click="applyFilter(question, option)">
                                        {{option}}
                                    </li>
                                    <li><hr class="dropdown-divider"></li>
                                    <li @click="applyFilter(question, '')"
                                        class="dropdown-item user-select-none pe-auto">
                                        Clear Selection
                                    </li>
                                </ul>

                            </div>
                            <div v-else>
                                {{ question.Name }}
                            </div>


                        </th>
                        <th>
                            Registered On
                        </th>
                        <th>
                            Code
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="response in currentPage">
                        <tr>
                            <td v-for="question in tableQuestions">
                                <a v-if="question.QuestionType == 'ImageUpload'"
                                    class="btn btn-outline-primary btn-sm"
                                    @click.stop
                                    :href="formatQuestionOutput(question.Code, response)"
                                    target="_blank">
                                    <i class="bi bi-download">

                                    </i>
                                </a>
                                <span v-else>
                                    {{ formatQuestionOutput(question.Code, response) }}
                                </span>
                            </td>
                            <td>
                                {{ response.SubmittedDateTime | moment('L LT') }}
                            </td>
                            <td>
                                <a :href="pollLink(response)"
                                target="_blank">
                                    {{ response.Id }}
                                </a>
                            </td>
                        </tr>
                    </template>
                </tbody>

            </table>      
        </div>
        
        <button type="button" 
                class="btn btn-success btn-lg btn-fixed-action"
                @click="downloadRegListToExcel">
            <i class="bi bi-cloud-download-fill"></i> Download
        </button>
    </div>
</template>
<style>
.poll-results-header {
}

.analytics-area {
    padding-top: 100px;
}

.poll-results-detail-table {
}

.response-table {
}

.response-table td > *,
.response-table th > * {
    min-width: 150px;
    max-width: 2000px !important;
}

.analytics-area .dropdown-menu.show {
    z-index: 999999;
}

</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import { getPollAppUrl } from './environment.hci';
import ExcelJS from 'exceljs'
import moment from 'moment'
import LineChart from './line-chart.vue'
import BarChart from './bar-chart.vue'

export default {
    components: { LineChart, BarChart },
    props: ['pollCode', 'showCode', 'code'],
    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            poll: null,
            requiresPassword: false,
            password: '',
            wrongPassword: false,
            lastRefreshed: null,

            textFilter: '',
            page: 0,
            showTop: 25,
            showTopFilters: [{
                Name: "10 Results/Page",
                Amount: 10
            },
            {
                Name: "25 Results/Page",
                Amount: 25
            },{
                Name: "50 Results/Page",
                Amount: 50
            },{
                Name: "100 Results/Page",
                Amount: 100
            },{
                Name: "All Results",
                Amount: 999999
            },],

            filters: {

            },

            summaryGraphFilter: '',
        }
    },

    watch: {
        showTop() {
            this.page = 0;
        },
    },

    computed: {

        hasValidFilters() {
            return Object.keys(this.filters).length > 0;
        },

        filteredResults() {
            let keys = Object.keys(this.filters);
            let toReturn = this.poll.Responses;

            let appliedFilters = [];

            keys.forEach((key, ix) => {
                if(!this.filters[key].SelectedOption) {
                    return;
                }

                appliedFilters.push(key);
            });

            if(appliedFilters.length == 0) {
                return toReturn;
            }

            for(let i = 0; i < appliedFilters.length; i++) {
                let questionCode = appliedFilters[i];
                let filterValue = this.filters[questionCode].SelectedOption;

                toReturn = toReturn.filter(response => {
                    let question = response.Questions.find(x => x.Code == questionCode);
                    
                    return question && question.Response == filterValue;
                });
            }

            return toReturn;
        },

        tableQuestions() {
            return this.poll.Questions.filter(x => 
                x.QuestionType != 'TextPrompt'
                && x.QuestionType != 'GroupInsertButton'
                && !(x.GroupTag && !x.SubGroup));
        },     
        
        totalPages() {
            return Math.ceil(this.filteredResults.length / this.showTop);
        },

        currentPage() {
            let toReturn = this.filteredResults;
            let startIndex = this.page * this.showTop;
            let endIndex = startIndex + this.showTop;
            toReturn = toReturn.slice(startIndex, endIndex);

            return toReturn;
        },

        summaryGraphData() {
            let results = this.poll.Responses;

            if(!this.summaryGraphFilter) {
                return {labels: [], datasets: {}};
            }

            let filter = this.filters[this.summaryGraphFilter];
            if(!filter) {
                return null;
            }

            let toReturn = {
                labels: filter.Options.sort(),
                datasets: [],
            };

            let data = [];

            toReturn.labels.forEach((label, ix) => {
                let dataUngrouped = results.map(x => x.Questions.find(y => y.Code == this.summaryGraphFilter).Response);
                
                data.push(dataUngrouped.filter(x => x == label).length);
            });

            toReturn.datasets.push({
                label: '# Responses',
                borderColor: '#187979',
                backgroundColor: '#187979',
                data: data
                //data: [...data.filter(x=>x == label)]
            });

            return toReturn;

        },

        answerRateData() {
            let results = this.filteredResults;
            let allResults = this.poll.Responses;


            let groupedByDay = allResults.reduce((acc, item) => {
                let dayKey = moment(item.SubmittedDateTime).local().format('YYYY-MM-DD');
                if(!acc[dayKey]) {
                    acc[dayKey] = [];
                }

                acc[dayKey].push(item);
                return acc;
            }, {});

            let labels = Object.keys(groupedByDay).sort();

            let toReturn = {
                labels: labels,
                datasets: [
                    {
                        label: 'Responses/Day (All)',
                        borderColor: '#f87979',
                        backgroundColor: '#f87979',
                        data: labels.map(l => groupedByDay[l].length),
                    }
                ]
            };

            if(results.length != allResults.length) {
                // We need to display the baseline.
                toReturn.datasets[0].borderColor = '#f8797944';
                toReturn.datasets[0].backgroundColor = '#f8797944';
                let groupedFilteredData = results.reduce((acc, item) => {

                    let dayKey = moment(item.SubmittedDateTime).local().format('YYYY-MM-DD');
                    if(!acc[dayKey]) {
                        acc[dayKey] = [];
                    }

                    acc[dayKey].push(item);
                    return acc;

                });

                let data = [];

                for(let i = 0; i < labels.length; i++) {
                    let label = labels[i];
                
                    let dailyData = groupedFilteredData[label];
                    if(!dailyData) {
                        data.push(0);
                    } else {
                        data.push(dailyData.length);
                    }
                }

                toReturn.datasets.push({
                    label: 'Responses/Day (Filtered)',
                    borderColor: '#187979',
                    backgroundColor: '#187979',
                    data: data
                });
            }

            console.log(toReturn);

            return toReturn; 
        },
    },

    methods: {

        applyFilter(question, response) {
            if(!response) {
                this.filters[question.Code].SelectedOption = null;
                return;
            }

            if(this.filters[question.Code].SelectedOption == response) {
                this.filters[question.Code].SelectedOption = null;
                return;
            }

            this.filters[question.Code].SelectedOption = response;
        },

        changePage(amount) {
            let newPageNumber = this.page + amount;

            if(newPageNumber < 0) {
                newPageNumber = this.totalPages - 1;
            }
            else if(newPageNumber >= this.totalPages) {
                newPageNumber = 0;
            }

            this.page = newPageNumber;
        },


        async downloadRegListToExcel() {
            let book = new ExcelJS.Workbook();
            let sheet = book.addWorksheet('Responses');

            let columns = [
                'Code',
                'Submitted',
            ];

            let questions = this.tableQuestions;

            console.log(questions);

            for(let i = 0; i < questions.length; i++) {
                columns.push(questions[i].Name);
            }

            sheet.addRow(columns);

            let toWrite = this.poll.Responses;

            for(let i = 0; i < toWrite.length; i++) {
                let poll = toWrite[i];

                let rowValues = [];
                rowValues.push(poll.Id);
                rowValues.push(poll.SubmittedDateTime);

                for(let j = 0; j < questions.length; j++) {
                    let question = questions[j];

                    let found = poll.Questions.find(x => x.Code == question.Code);

                    let valueToWrite = '';

                    if(found) {

                        let response = found.Response;
                        if(found.QuestionType == "MultipleChoice") {
                            let answer = question.Answers.find(x => x.Response == found.Response);

                            if(answer) {
                                response = `${answer.DisplayText}`;
                            }
                        }

                        valueToWrite = response;
                    }
                    rowValues.push(valueToWrite);
                }

                sheet.addRow(rowValues);

            }

            sheet.columns.forEach((column, i) => {
                let maxLength = 0;
                column["eachCell"]({ includeEmpty: true }, function (cell) {
                    var columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength ) {
                        maxLength = columnLength + 5;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            });

            for(let i = 0; i < columns.length; i++) {
                sheet.getCell(`${String.fromCharCode('A'.charCodeAt() + i)}1`).font = {
                    bold: true
                };
            }

            sheet.autoFilter = {
                from: 'A1',
                to: `${String.fromCharCode('A'.charCodeAt() + columns.length - 1)}1`
            };

            let buffer = await book.xlsx.writeBuffer();

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            let blob = new Blob([buffer], {type: fileType});

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            link.download = `Results_${moment().format('YYYY_MM_DD_HH_mm')}_${this.showCode}_${this.poll.Name}.xlsx`;

            link.click();
            document.removeChild(link);
        },

        async build() {
            this.submitting = true;

            let uri = `/api/pollresult/${this.showCode}/${this.pollCode}/${this.code}`;

            if(this.password) {
                uri = `${uri}?p=${this.password}`;
            }

            let initialResult = await this.tryGetAnonymous(uri);

            if(initialResult.ErrorCodes && initialResult.ErrorCodes.length > 0 && initialResult.ErrorCodes[0] == 'ERR_403') {
                this.requiresPassword = true;
                if(this.password) {
                    this.wrongPassword = true;
                }
            } else {
                this.requiresPassword = false;
                this.poll = initialResult.Result;
                this.lastRefreshed = new Date();

                for(const question of this.tableQuestions) {
                    console.log(question.Name, question.QuestionType)
                    if(question.QuestionType.indexOf("MultipleChoice") == -1
                        && question.QuestionType != 'INTERNAL') {
                        continue;
                    }

                    let options = [];

                    if(question.QuestionType == 'INTERNAL') {

                        let responses = this.poll.Responses.map(x => x.Questions.find(y=>y.Code == question.Code).Response);
                        responses = [...new Set(responses)];
                        options = responses;
                    } else {
                        if(!question.Answers) {
                            continue;
                        }
                        options = question.Answers.map(x => x.Response);
                    }

                    if(!this.filters[question.Code]) {
                        Vue.set(this.filters, question.Code, {
                            Name: question.Name,
                            Options: [],
                            SelectedOption: null,
                            Code: question.Code
                        });
                    }

                    this.filters[question.Code].Name = question.Name;
                    this.filters[question.Code].Options = options;
                }

                if(!this.summaryGraphFilter) {
                    let filterCodes = Object.keys(this.filters);
                    this.summaryGraphFilter = filterCodes.length > 0 ? filterCodes[0] : '';
                }
            }

            this.submitting = false;
        },

        pollLink: function(poll) {
            return `${getPollAppUrl()}/#/pp/${this.poll.ShowCode}/${this.poll.Code}?r=${poll.Id}`;
        },


        formatQuestionOutput(code, response) {
            if(!response) {
                return 'NF';
            }
            
            let question = response.Questions.find(x => x.Code == code);

            if(!question || !question.Response) {
                return '';
            }

            if(this.isMultipleChoiceQuestion(question)) {
                let responseText = question.Response;   
                let response = question.Answers.find(x => x.Response == responseText);

                if(!response) {
                    return responseText;
                }

                return response.DisplayText;
            }

            return question.Response;
        },


        isMultipleChoiceQuestion(question) {
            return !this.isQuestionGroupTemplate(question) 
                && (
                    question.QuestionType == 'MultipleChoice'
                    || question.QuestionType == 'MultipleChoiceMultiSelect'
                    || question.QuestionType == 'MultipleChoiceDropdown');
        },        

        isQuestionGroupTemplate(question) {
            return question.GroupTag && !question.SubGroup;
        },

    },

    mounted() {
        this.submitting = true;
        this.build();
    }
}
</script>