<template>
    <form class="form-group" v-if="!submitting">
        <div class="modal fade" 
            id="noPrimaryEmailWarning"
             ref="noPrimaryEmailWarn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            Attendees with no Primary Email selected
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <p>There are attendees selected without an primary email address, are you sure you want to continue?</p>    
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                @click="onSave('Queued')">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between mt-1">
            <button type="button"
                    class="btn btn-secondary"
                    @click="onCancel">
                Cancel
            </button>

            <div class="btn-group">
                <button type="button"
                        class="btn btn-outline-primary"
                        @click="onSave('draft')">
                    Save Draft
                </button>
                <button type="button"
                        class="btn btn-primary"
                        @click="determineIfModalNeeded">
                    Save and Send
                </button>
            </div>
        </div>

        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>

        <div class="row mt-1">
            <div class="col">
                <small>Email Template</small>
                <select class="form-select form-select-sm" v-model="copyTemplate">
                    <option selected :value="null">(Select template...)</option>
                    <option v-for="qt in templates" 
                            :value="qt"
                            :key="qt.Code">
                        {{qt.Subject}} 
                    </option>
                </select>
            </div>
            <div class="col-auto">
                <div class="form-check mt-2">
                    <input class="form-check-input"
                           id="sendUniqueOnly"
                           type="checkbox"
                           v-model="editCampaign.DistinctEmailsOnly">
                    <label class="form-check-label"
                           for="sendUniqueOnly">
                        Send to unique emails only
                    </label>
                </div>

            </div>
            <div class="col">
                <ctk-date-time-picker v-model="editCampaign.ScheduledDateTime"
                                        format="YYYY-MM-DDTHH:mmZ"
                                        minuteInterval="5"
                                        label="Send On"
                                        :disabled="submitting"
                                        class="mt-1">
                </ctk-date-time-picker>
            </div>
            <div class="col">
                <small>Reply to...</small>
                <input type="text" 
                        class="form-control form-control-sm"
                        placeholder="Reply to address override" 
                        v-model="editCampaign.Request.ReplyTo">
            </div>
        </div>
        <div class="row mt-2 h-100">
            <div class="col-4">
                <div class="btn-group w-100">
                    <button type="button" class="btn btn-sm"
                            @click="sendToMode = 'attendees'"
                            :class="{'btn-outline-primary': sendToMode == 'csv', 'btn-primary': sendToMode == 'attendees'}">
                        Attendees
                    </button>
                    <button type="button" class="btn btn-sm"
                            @click="sendToMode = 'csv'"
                            :class="{'btn-outline-primary': sendToMode == 'attendees', 'btn-primary': sendToMode == 'csv'}">
                        CSV-Style
                    </button>

                </div>

                <div v-show="sendToMode == 'csv'" class="h-100">
                    <div>
                        One recipient per line. You can either enter just an email, or use <span>first,last,email</span>.
                    </div>
                    <textarea class="form-control h-100"  
                            v-model="arbitraryEmails">
                    </textarea>
                </div>          
                <div v-show="sendToMode == 'attendees'">
                    <attendee-picker :showDetails="show"
                                :attendeesSelected="attendeesSelected"
                                ref="attendeePicker">
                    </attendee-picker>
                </div>     
            </div>
            <div class="col-8">
                <notification-template-editor  :dontShowSaveCancelButtons="true"
                                            :showCode="show.Code"
                                            :template="editTemplate"
                                            v-if="editTemplate">
                </notification-template-editor>
            </div>
        </div>
        
    </form>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import { Modal } from 'bootstrap'

export default {
    props: ['show', 'modeTitle', 'templates','currentCampaign'],
    mixins: [
        Token,
        Common
    ],

    data() {
        return {    
            editCampaign: null,
            arbitraryEmails: "",
            copyTemplate: null,
            editTemplate: null,
            attendeesSelected: [],
            modal: null,

            sendToMode: "attendees"
        }
    },

    watch: {
        copyTemplate() {
            this.flashCopyTemplateToRequest();
        }
    },


    created() {
        this.submitting = true;
        this.build();
    },

    methods: {
        determineIfModalNeeded(){
            let noEmailCount = 0;
            var recipients = this.$refs.attendeePicker.getSelectedAttendees();
            for(var recipient of recipients){
                if(!recipient.Details.PrimaryEmail){
                    noEmailCount += 1;
                }
            }

            if(noEmailCount > 0){
                if(!this.modal) {
                    this.modal = new Modal(this.$refs.noPrimaryEmailWarn);
                }
                this.modal.show();
            }
            else{
                this.onSave('Queued');
            }
        },
        flashCopyTemplateToRequest() {
            if(!this.copyTemplate) {
                return;
            }

            console.log(this.copyTemplate);
            this.editTemplate = null;

            Vue.nextTick(() => {
                this.editTemplate = JSON.parse(JSON.stringify(this.copyTemplate));
                this.editCampaign.Request.TemplateCode = this.copyTemplate.Code;
            });

        },

        async build() {
            this.submitting = true;

            this.editCampaign = JSON.parse(JSON.stringify(this.currentCampaign));

            this.editTemplate = JSON.parse(JSON.stringify(this.editCampaign.Request.Template));

            this.arbitraryEmails = '';
            for(let i = 0; i < this.editCampaign.Request.To.length; i++) {
                let recipient = this.editCampaign.Request.To[i];

                if(recipient.AttendeeCode) {
                    this.attendeesSelected.push(recipient.AttendeeCode);
                } else if (recipient.Email) {
                    this.arbitraryEmails += `${recipient.Email}\n`
                }
            }

            this.submitting = false;
        },

        validateForm: function() {
            this.validateErrors = [];
            
            if(this.$refs.attendeePicker.getSelectedAttendees().length == 0 && !this.arbitraryEmails){
                this.validateErrors.push("Please select or enter at least one recipient.");
            }

            return this.validateErrors.length === 0;
        },

        async onSave(statusCode) {

            this.submitting = true;
            this.editCampaign.Request.To = [];

            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            var recipients = this.$refs.attendeePicker.getSelectedAttendees();

            if(this.arbitraryEmails && this.arbitraryEmails.length > 0){
                let arbitraryRecipients = this.arbitraryEmails.split('\n');
                if(arbitraryRecipients){
                    for(let ix in arbitraryRecipients){
                        let arbRecipient = arbitraryRecipients[ix];

                        if(!arbRecipient) {
                            continue;
                        }

                        if(arbRecipient.indexOf("@") == -1) {
                            continue;
                        }
                        
                        if(arbRecipient.indexOf(",") != -1) {
                            let components = arbRecipient.split(",");
                            if(components.length != 3) {
                                continue;
                            }

                            let firstName = components[0];
                            let lastName = components[1];
                            let email = components[2];

                            this.editCampaign.Request.To.push({
                                Email: email,
                                FirstName: firstName,
                                LastName: lastName
                            });

                        } else {
                            this.editCampaign.Request.To.push({
                                Email: arbitraryRecipients[arbRecipient]
                            });
                        }
                    }
                }
            }
                 
            for(var recipient of recipients){
                this.editCampaign.Request.To.push({
                    AttendeeCode: recipient.Details.Code,
                });
            }

            this.editCampaign.Request.ScheduledDateTime = this.editCampaign.ScheduledDateTime;
            this.editCampaign.TemplateSubject = this.editTemplate.Subject;
            this.editTemplate.RecipientCount = this.editCampaign.Request.To.length;
            this.editCampaign.StatusCode = statusCode;
            this.editCampaign.Request.Template = this.editTemplate;

            console.log(this.editCampaign)
            if(statusCode == 'Queued'
                && !confirm(`You are about to send this campaign to ${this.editCampaign.Request.To.length} recipient(s). This cannot be undone. Are you sure?`)) 
            {
                this.submitting = false;
                return;
            }


           
            try{
                let r = await this.tryPost(`/api/communications/campaign`, 
                    JSON.stringify(this.editCampaign), 
                    'application/json');
                    
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                else {
                    this.$emit('saved', this.poll);
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;      
        },  

        onCancel(){
            this.$emit('cancel');
        },
    }
}
</script>
