<template>
    <div class="w-100" :style="`height: ${graphHeight}px`">
        <LineGraph :options="graphOptions" 
              :data="data"></LineGraph>

    </div>
</template>
<script>
import { Bar, Line as LineGraph } from 'vue-chartjs'

export default {
    name: 'LineChart',
    props: [
        'height', 
        'data', 
        'options',
        'useY2'],

    computed: {
        graphHeight() {
            if(!this.height) {
                return 300;
            }

            return this.height;
        },

        graphOptions() {
            if(!this.options) {

                let scales = {
                    x: {
                        display: true,
                        grid: {
                            color: '#444444'
                        }
                    },
                    y: {
                        stacked: false,
                        position: 'left',
                        min: 0,
                        //max: 100,
                        ticks: {
                            color: 'white',
                        },
                        grid: {
                            color: '#444444'
                        }
                    }
                };


                return {
                    animation: {
                        duration: 0
                    },
                    scales: scales,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            mode: 'index'
                        },

                        filler: {
                            propagate: true
                        }
                    }

                };
            }

            return this.options;
        }
    },

    components: {
        Bar,
        LineGraph
    },
}
</script>