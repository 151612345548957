<template>
    <form class="d-flex h-100 flex-column">

        <div class="d-flex justify-content-between mt-1"
             v-if="!dontShowSaveCancelButtons">
            <button type="button"
                    class="btn btn-secondary"
                    @click="onCancel">
                Cancel
            </button>

            <div class="btn-group">
                <button type="button"
                        class="btn btn-primary"
                        @click="onSave">
                    Save
                </button>
            </div>
        </div>
        
        <div class="form-floating mt-2" v-if="allCopyTemplates">
            <select class="form-select" @change="loadCopyData" v-model="copyTemplate">
                <option :value="null">(No Template Selected)</option>
                <option v-for="cTemplate in allCopyTemplates" :value="cTemplate">
                    {{cTemplate.Subject}}
                </option>
            </select>
            <label>
                Copy from Template
            </label>
        </div>
        <div class="form-floating mt-2">
            <input :disabled="submitting"
                    class="form-control"
                    v-model='editNotification.Subject' />
            <label>
                Subject
            </label>
        </div>
        <div class="row mt-1 flex-grow-1">
            <div class="col-6 h-100">
                <div class="btn-group mt-1 mb-1">
                    <button class="btn btn-outline-primary btn-sm"
                            v-for="placeholder in placeholders"
                            :key="placeholder.Code"
                            type="button"
                            @click="insertPlaceholder(placeholder.Code)">
                        {{placeholder.Label}}
                    </button>
                </div>
                <textarea v-model="editNotification.Body"
                          v-debounce:300="onBodyEdited"
                          class="form-control h-100 code-editor"
                          ref="htmlEditor"
                          @keydown="onTextAreaKeyDown">
                </textarea>
            </div>
            <div class="col-6 html-preview">
                <div class="border p-2 bg-white">
                    <div v-html="debugOutput">
                    </div>
                </div>
            </div>
        </div>

        <div class="form-floating mt-2" v-if="!dontShowSaveCancelButtons">
            <input class="form-control"
                   v-model='editNotification.Code' />
            <label>
                Code
            </label>
        </div>
        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>
    </form>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
export default {
    props: ['showCode', 'template', 'allCopyTemplates','dontShowSaveCancelButtons'],
    mixins: [
        Token,
        Common
    ],
    
    data() {
        return {
            placeholders: [
                {
                    Label: "Login Link",
                    Code: "{{EventAppUrl}}"
                },
                {
                    Label: "Login Component",
                    Code: "{{LoginBase64}}"
                },
                {
                    Label: "Edit Poll",
                    Code: "{{EditPoll}}"
                },
                {
                    Label: "First Name",
                    Code: "{{FirstName}}"
                },
                {
                    Label: "Last Name",
                    Code: "{{LastName}}"
                },
                {
                    Label: "Attendee Code",
                    Code: "{{AttendeeCode}}"
                },
                {
                    Label: "PIN",
                    Code: "{{AttendeePin}}"
                },
                {
                    Label: "Show Code",
                    Code: "{{ShowCode}}"
                },
                {
                    Label: "Show Name",
                    Code: "{{ShowName}}"
                },
            ],

            debugOutput: 'Waiting...',

            editNotification: null,
            
            copyTemplate: null,

            editorSettings: {
                modules: {
                    toolbar: {
                        container: '#toolbar'
                    },
                }
            }
        }
    },

    created: function() {
        this.editNotification = this.template;

        if(!this.editNotification.ICalBody) {
            Vue.set(this.editNotification, 'ICalBody', '');
        }
        
        this.onBodyEdited();
    },

    methods: {
        insertPlaceholder(code) {
            let textArea = this.$refs.htmlEditor;
            textArea.focus();

            let oldSelectionStart = textArea.selectionStart;

            this.editNotification.Body =
                this.editNotification.Body.substr(0, textArea.selectionStart) + code + this.editNotification.Body.substr(textArea.selectionStart);

            Vue.nextTick(() => {
                textArea.selectionEnd = oldSelectionStart + code.length;
            });

            //textArea.setRangeText(code, textArea.selectionStart, textArea.selectionStart, 'end');
            this.onBodyEdited();
        },

        async onBodyEdited() {
            let result = await this.tryPost("/api/email/templates/preview", JSON.stringify({
                TemplateRawCode: this.editNotification.Body
            }), "application/json");

            try {
                if(result.Result) {
                    this.debugOutput = result.Result;
                }
            } catch(ex) {

            }
        },

        onTextAreaKeyDown(e) {
            if(e.keyCode === 9) {
                e.preventDefault();
                let textArea = this.$refs.htmlEditor;

                textArea.setRangeText('  ', textArea.selectionStart, textArea.selectionStart, 'end');
            }
        },

        validateForm: function() {

            this.validateErrors = [];
      
            var str = this.editNotification.Subject;
            if (this.editNotification.Subject && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Subject cannot be only spaces.");
            }
            if(!this.editNotification.Subject) {
                this.validateErrors.push("Subject cannot be blank.");
            }

            return this.validateErrors.length === 0;
        },

        async onSave() {
            this.submitting = true;
        
            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }
            
            this.editNotification.Code = (this.editNotification.Code == null) ? null : this.editNotification.Code.toUpperCase();
            this.editNotification.ShowCode = this.showCode;
           
           try{
               let r = await this.tryPost('/api/communications/template', 
                JSON.stringify(this.editNotification), 
                'application/json');
                    
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                else {
                    this.$emit('saved', this.poll);
                }
           }
           catch(ex){
               console.log(ex);
           }
           this.submitting = false;
            
        },

        loadCopyData(){
            if(this.copyTemplate){
                this.editNotification.Body = this.copyTemplate.Body;
                this.editNotification.Subject = this.copyTemplate.Subject;
                this.editNotification.Footer = this.copyTemplate.Footer;
            }
        },

        onCancel(){
            this.$emit('cancel');
        },
    }
}
</script>
