<template>
    <div v-if="submitting">
        <loading></loading>
    </div>
    <div v-else
         class="container-fluid scrolling-columns">
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <span class="flex-fill fs-5 ps-1">
                            Replays
                        </span>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn btn-outline-success"
                                    @click="showUploadVideoControls">
                                Add...
                            </button>
                            <button class="btn btn-outline-primary"
                                    @click="build">
                                Refresh
                            </button>
                        </div>

                    </div>
                </div>

                <div class="row mt-2" v-if="candidateVideo">
                    <div class="col">
                        <h3>New Video</h3>
                        <div class="input-group">
                            <span class="input-group-text">Video Name</span>
                            <input placeholder="Name" v-model="candidateVideo.title" class="form-control form-control-sm">
                        </div>
                        <div class="input-group mt-2 mb-2">
                            <span class="input-group-text">Associated Session</span>
                            <select class="form-select form-select-sm"
                                    v-model="candidateVideo.code">
                                <option :value="showCode">
                                    (No Session Selected)
                                </option>
                                <optgroup v-for="(category, key) in sessions"
                                        :key="key"
                                        :label="getSessionCategoryName(key)">
                                    <option v-for="session in category"
                                            :key="session.Code"
                                            :value="`${session.Code}`">
                                        {{session.Code}} - {{ session.Name }}
                                    </option>
                                </optgroup>
                            </select>                            
                        </div>
                        <button class="btn btn-primary btn-sm" @click="requestUploadUrl" v-if="!candidateVideo.uploadTargetUrl">
                            Request Upload URL
                        </button> 
                        <button class="btn btn-danger btn-sm" @click="cancelUpload">
                            Cancel
                        </button>
                    </div>
                    <div class="col" v-if="candidateVideo.uploadTargetUrl">
                        <mux-uploader ref="videoUploader" :endpoint="candidateVideo.uploadTargetUrl"></mux-uploader>
                    </div>
                </div>

                <!--
                
                    public string ShowCode { get; set; }
    public string LiveStreamId { get; set; }
    public string Name { get; set; }
    public string StreamKey { get; set; }
    public string PlaybackKey { get; set; }
    public string NextAssociatedSessionCode { get; set; }
    public string Status { get; set; }
                
                -->
                <div class="row">
                    <div class="col">
                        <table class="table table-responsive table-action table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Duration
                                    </th>
                                    <th>
                                        Controls
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="selectedRow" class="table-active">
                                    <td>
                                        {{ selectedRow.Name }}<br>
                                        <small> 
                                            {{ selectedRow.AssociatedSessionCode }}
                                        </small>
                                    </td>
                                    <td>
                                        <span v-if="selectedRow.DurationSec">
                                            {{ friendlyDuration(selectedRow.DurationSec) }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="input-group">
                                            <button class="btn btn-outline-danger btn-sm"
                                                    @click="clearAssignment(selectedRow)">
                                                Clear
                                            </button>
                                            <select class="form-select form-select-sm"
                                                    v-model="selectedRow.AssociatedSessionCode">
                                                <option :value="showCode">
                                                    (No Session Selected)
                                                </option>
                                                <optgroup v-for="(category, key) in sessions"
                                                        :key="key"
                                                        :label="getSessionCategoryName(key)">
                                                    <option v-for="session in category"
                                                            :key="session.Code"
                                                            :value="`${showCode}_${session.Code}`">
                                                        {{session.Code}} - {{ session.Name }}
                                                    </option>
                                                </optgroup>
                                            </select>

                                            <button class="btn btn-outline-primary btn-sm"
                                                    @click="assignToRoom(selectedRow)">
                                                Assign
                                            </button>
                                        </div>

                                    </td>
                                </tr>

                                <tr v-for="video in replays" 
                                    v-show="selectedRow != video" 
                                    :class="{'table-active': selectedRow && selectedRow == video}">
                                    <td @click="selectedRow = video">
                                        {{ video.Name }}<br>
                                        <small> 
                                            {{ video.AssociatedSessionCode }}
                                        </small>

                                    </td>
                                    <td>
                                        <span v-if="video.DurationSec">
                                            {{ friendlyDuration(video.DurationSec) }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="input-group">

                                            <button class="btn btn-outline-danger btn-sm"
                                                    @click="clearAssignment(video)">
                                                Clear
                                            </button>
                                            <button class="btn btn-outline-primary btn-sm"
                                                    @click="assignToRoom(video)">
                                                Assign
                                            </button>
                                            <button class="btn btn-outline-primary btn-sm"
                                                    @click="copyMuxPlaybackIdToClipboard(video)">
                                                PID
                                            </button>
                                            <button class="btn btn-outline-primary btn-sm"
                                                    @click="askForMasterAccess(video)">
                                                Refresh DL
                                            </button>
                                            <a v-if="video.MasterStatus == 'ready' && video.MasterUrl" :href="video.MasterUrl" 
                                               target="blank" download class="btn btn-sm btn-outline-success">
                                                <i class="bi bi-download"></i>
                                            </a>                                
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <span class="flex-fill fs-5 ps-1">
                            Session Mappings
                        </span>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn btn-outline-primary"
                                    @click="build">
                                Refresh
                            </button>
                        </div>
                    </div>                    
                </div>

                <table class="table table-responsive">
                    <tbody>
                        <template v-for="(category, key) in sessions">
                            <tr>
                                <th colspan="3">
                                    {{ getSessionCategoryName(key) }}
                                </th>
                            </tr>
                            <tr v-for="session in category" :key="session.Code">
                                <td>
                                    {{ session.Name }}<br>
                                    <i v-if="getAssociatedReplay(session)">🎥{{ getAssociatedReplay(session) }}</i>
                                </td>
                                <td width="150">
                                    {{ session.SessionStartDateTime | moment('YY-MM-DD HH:mm') }}
                                </td>
                                <td>
                                    <div v-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'MuxLive'"
                                         class="badge text-bg-success">
                                        Mux Live
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'MuxAsset'"
                                         class="badge text-bg-primary">
                                        Replay
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'Video' && session.PrimaryMedia.Uri"
                                         class="badge text-bg-warning">
                                        Video (M3U8)
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'LiveWebSource' && session.PrimaryMedia.Uri"
                                        class="badge text-bg-warning">
                                        Embed
                                    </div>
                                    <div v-else
                                         class="badge text-bg-secondary">
                                        (None)
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>
<script>
import "@mux/mux-player";
import "@mux/mux-uploader";

import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import ExcelJS from 'exceljs'
import Toastify from 'toastify-js'
import { offset } from "@popperjs/core";

export default {
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],

    methods: {

        async requestUploadUrl() {
            this.submitting = true;

/*

    public class RequestCreateUploadRouteModel
    {
        public string ShowCode { get; set; }
        public string VideoCode { get; set; }
        public string CorsRoute { get; set; }
        public string VideoTitle { get; set; }
    }
    [HttpPost]
    [Route("api/mux/asset/upload")]


*/

            let toPost = {
                ShowCode: this.showCode,
                VideoCode: this.candidateVideo.code,
                CorsRoute: window.location.origin,
                VideoTitle: this.candidateVideo.title
            };

            let url = `/api/mux/asset/upload`;
            var result = await this.tryPost(url, JSON.stringify(toPost), 'application/json');

            if(result.Result) {
                this.candidateVideo.uploadTargetUrl = result.Result.UploadUrl
            }

            this.submitting = false;

            Vue.nextTick(() => {
                this.$refs.videoUploader.addEventListener('success', () => {
                    this.candidateVideo = null;
                            
                    Toastify({
                        text: "Uploaded video to Tractus and Mux!",
                        duration: 2500,
                        gravity: "top",
                        position: "right",
                    }).showToast();
                });
            });
        },


        friendlyDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;

            return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
        },

        getAssociatedReplay(session) {
            if(!session.PrimaryMedia 
                || session.PrimaryMedia.MediaType != 'MuxAsset'
                || !session.PrimaryMedia.Uri) {
                return "";
            }

            let replay = this.replays.find(x => x.PlaybackId == session.PrimaryMedia.Uri);

            if(!replay) {
                return "";
            }

            return replay.Name;
        },

        async askForMasterAccess(stream) {
            this.submitting = true;
            let url = `/api/mux/assets/${this.showCode}/${stream.VideoCode}/enable-master`;
            var result = await this.tryPut(url);
            this.build();
        },

        async assignToRoom(stream) {
            this.submitting = true;
            let url = `/api/mux/assets/${this.showCode}/${stream.VideoCode}/assign/${stream.AssociatedSessionCode}`;
            var result = await this.tryPut(url);
            this.build();
        },

        async clearAssignment(stream) {
            // api/mux/livestream/{showCode}/{streamCode}/assign/{sessionCode?}
            this.submitting = true;
            let url = `/api/mux/assets/${this.showCode}/${stream.VideoCode}/assign/${this.showCode}`;
            var result = await this.tryPut(url);
            this.build();
        },

        getSessionCategoryName(categoryCode) {
            let category = this.showDetails.SessionCategories.find(x => x.Code == categoryCode);
            if(!category) {
                return categoryCode;
            }

            return category.Name;
        },

        async build() {
            this.submitting = true;
            
            try {

                let url = `/api/mux/assets/${this.showCode}`;
                let result = await this.tryGet(url);

                this.replays = result.Result.sort((a, b) => a.Name.localeCompare(b.Name));

                result = await this.tryGet(`/api/show/${this.showCode}/sessions`);
                this.sessions = result.Result;

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        },        

        createRow() {
            /*
                
                    public string ShowCode { get; set; }
    public string LiveStreamId { get; set; }
    public string Name { get; set; }
    public string StreamKey { get; set; }
    public string PlaybackKey { get; set; }
    public string NextAssociatedSessionCode { get; set; }
    public string Status { get; set; }

            */
            let toPush = {
                ShowCode: this.showCode,
                LiveStreamId: '',
                Name: 'New Stream Endpoint',
                StreamKey: '',
                PlaybackKey: '',
                NextAssociatedSessionCode: this.showCode,
                Status: 'new',
            };

            this.streams.splice(0, 0, toPush);
            this.selectedRow = toPush;
        },

        async getUploadEndpoint(e) {
            console.warn("Endpoint upload event fired!", e);
        },

        async saveChanges(stream) {
            this.submitting = true;

            let toSave = stream;

            let url = `/api/mux/asset`;
            var result = await this.tryPost(
                    url,
                    JSON.stringify(toSave), 
                    'application/json');

            this.build();
        },

        async deleteStream(stream) {
            if(!confirm("Are you absolutely sure you want to delete this replay?")) {
                return;
            }

            if(!stream.LiveStreamId) {
                this.build();
                return;
            }

            this.submitting = true;
            let url = `/api/mux/asset/${this.showCode}/${stream.VideoCode}`;
            var result = await this.tryDelete(url);    
            
            this.build();
        },

        showUploadVideoControls() {
            this.candidateVideo = {
                title: "",
                code: "",
                uploadTargetUrl: '',
            };
        },

        onControlChannelMessage(data) {

            console.warn(data);
            if(!data.TypeCode ) {
                return;
            }

            if(data.TypeCode == 'EntityUpdated') {
                this.build();
                return;
            }

            if(data.TypeCode == 'MuxVideoUpdated') {
                if(data.ShowCode != this.showCode) {
                    console.warn("Mismatch of show code. Return");
                    return;
                }

                this.build();
            }
        },

        async copyMuxPlaybackIdToClipboard(video) {
            await navigator.clipboard.writeText(video.PlaybackId);

            Toastify({
                text: "Copied Mux video playback ID to clipboard.",
                duration: 2000,
                gravity: "top",
                position: "right",
            }).showToast();            
        },

        cancelUpload() {
            this.candidateVideo = null;
        }
    },

    data(){ 
        return{           
            replays: [],
            selectedRow: null,
            sessions: [],

            candidateVideo: null,

        }
    },

    async created(){
        await this.build();
        this.bus.$on('Control-Channel-Message', this.onControlChannelMessage);
    },

    beforeDestroy() {
        this.bus.$off('Control-Channel-Message', this.onControlChannelMessage);
    }
}
</script>