<template>
    <div>
        <div class="row pb-2 pt-2 sticky-top bg-dark">
            <div class="col">
                <button @click="onCancel" 
                        class="btn btn-danger form-control"
                        type="button">
                    Cancel 
                </button>
            </div>
            <div class="col">
                <div class="btn-group w-100">
                    <button class="btn btn-primary"
                            @click="editorMode = 'details'"
                            :class="{'active': editorMode == 'details'}">
                        Poll Details
                    </button>
                    <button class="btn btn-primary"
                            @click="editorMode = 'questions'"
                            :class="{'active': editorMode == 'questions'}">
                        Questions
                    </button>
                </div>

            </div>
            <div class="col-auto">
                <div class="btn-group">
                    <button class="btn btn-primary"
                            @click="importFromClipboard">
                        Import
                    </button>
                    <button class="btn btn-primary"
                            @click="exportToClipboard">
                        Export
                    </button>                    
                </div>
            </div>
            <div class="col">
                <button @click="onSave" 
                        class="btn btn-success form-control"
                        type="button">
                    Save
                </button>
            </div>
        </div>


        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col">
            <template v-if="editorMode == 'details'">
                <div class="form-floating mb-2">
                    <input :disabled="submitting"
                            placeholder="Poll Name"
                            class="form-control"
                            v-model='editPoll.Name' />
                    <label>
                        Poll Name
                    </label>
                </div>
                <div class="form-floating mb-2">
                    <input :disabled="submitting"
                            placeholder="Poll Code"
                            class="form-control"
                            v-model='editPoll.Code' />
                    <label>
                        Code (DO NOT ALTER)
                    </label>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="btn-group w-100 mb-1">
                            <button @click="showTab = 'landing'"
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    :class="{'active': showTab == 'landing'}">
                                Landing    
                            </button>
                            <button @click="showTab = 'header'"
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    :class="{'active': showTab == 'header'}">
                                <span v-if="!editPoll.HeaderText">⚠️</span>Header    
                            </button>
                            <button @click="showTab = 'conclusion'"
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    :class="{'active': showTab == 'conclusion'}">
                                <span v-if="!editPoll.ConclusionMessage">⚠️</span>Conclusion    
                            </button>
                            <button @click="showTab = 'closed'"
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    :class="{'active': showTab == 'closed'}">
                                <span v-if="!editPoll.ClosedMessage">⚠️</span>Closed
                            </button>
                            <button @click="showTab = 'footer'"
                                    type="button"
                                    class="btn btn-sm btn-outline-primary"
                                    :class="{'active': showTab == 'footer'}">
                                <span v-if="!editPoll.FooterContent">⚠️</span>Footer    
                            </button>                            
                        </div>

                    </div>
                </div>

                <div class="row poll-header-editor">
                    <div class="col" v-if="showTab == 'landing'">
                        <div class="form-floating mb-2">
                            <textarea :disabled="submitting" 
                                    placeholder="Landing Page" 
                                    class="form-control form-control-sm" 
                                    style="min-height: 250px;"
                                    v-model="editPoll.LandingPageMessage"
                                    @keydown="onTextAreaKeyDown">
                            </textarea>
                            <label>
                                Landing Page
                            </label>
                        </div>
                    </div>
                    <div class="col" v-if="showTab == 'header'">
                        <div class="form-floating mb-2">
                            <textarea :disabled="submitting" 
                                    placeholder="Header" 
                                    style="min-height: 250px;"
                                    class="form-control form-control-sm" 
                                    v-model="editPoll.HeaderText"
                                    @keydown="onTextAreaKeyDown">
                            </textarea>
                            <label>
                                Header
                            </label>
                        </div>
                    </div>
                    <div class="col" v-if="showTab == 'conclusion'">
                        <div class="form-floating mb-2">
                            <textarea :disabled="submitting" 
                                    placeholder="Conclusion Body" 
                                    style="min-height: 250px;"
                                    class="form-control form-control-sm"
                                    v-model="editPoll.ConclusionMessage"
                                    @keydown="onTextAreaKeyDown">
                            </textarea>
                            <label>
                                Conclusion Body
                            </label>
                        </div>
                    </div>
                    <div class="col" v-if="showTab == 'closed'">
                        <div class="form-floating mb-2">
                            <textarea :disabled="submitting" 
                                    placeholder="Poll Closed Body" 
                                    style="min-height: 250px;"
                                    class="form-control form-control-sm"
                                    v-model="editPoll.ClosedMessage"
                                    @keydown="onTextAreaKeyDown">
                            </textarea>
                            <label>
                                Poll Closed Body
                            </label>
                        </div>
                    </div>
                    <div class="col" v-if="showTab == 'footer'">
                        <div class="form-floating mb-2">
                            <textarea :disabled="submitting" 
                                    placeholder="Poll Footer" 
                                    class="form-control form-control-sm"
                                    v-model="editPoll.FooterContent"
                                    @keydown="onTextAreaKeyDown">
                            </textarea>
                            <label>
                                Footer Content
                            </label>
                        </div>
                    </div>                
                </div>  


                <div class="row">
                    <div class="col-xl-6">
                        <div class="form-floating mb-2">
                            <input :disabled="submitting"
                                    placeholder="Submit Button Text"
                                    class="form-control form-control-sm"
                                    v-model='editPoll.SubmitButtonText' />
                            <label>
                                Submit Button Override Text
                            </label>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="form-floating mb-2">
                            <input :disabled="submitting"
                                    placeholder="Redirect to URL"
                                    class="form-control form-control-sm"
                                    v-model='editPoll.RedirectToUrl' />
                            <label>
                                Redirect to URL on Complete
                            </label>
                        </div>                
                    </div>
                </div>






                <div class="row mb-2">
                    <div class="col-md-2">
                        <div class="form-checkbox">
                            <label class="form-check-label">
                                <input  type="checkbox" 
                                        class="form-check-input"
                                        v-model="editPoll.IsOpenOverride">
                                    Open 
                            </label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-checkbox">
                            <label class="form-check-label">
                            <input  type="checkbox"
                                    class="form-check-input" 
                                    v-model="editPoll.AllowAnonymousResponse">
                                            Public
                            </label>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-checkbox">
                            <label class="form-check-label">
                            <input  type="checkbox"
                                    class="form-check-input" 
                                    v-model="editPoll.AllowMultiSubmit">
                                Multi-Submit
                            </label>
                        </div>
                    </div>


                    <div class="col-md-2">
                        <div class="form-checkbox">
                            <label class="form-check-label">
                            <input  type="checkbox"
                                    class="form-check-input" 
                                    v-model="editPoll.AllowEditResponse">
                                Editable
                            </label>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-checkbox">
                            <label class="form-check-label">
                            <input  type="checkbox"
                                    class="form-check-input" 
                                    v-model="editPoll.HiddenFromList">
                                Hidden
                            </label>
                        </div>
                    </div>
                    <div class="col-md-2" v-if="editPoll.TypeCode == 'RegForm'">
                        <div class="form-checkbox">
                            <label class="form-check-label">
                            <input  type="checkbox"
                                    class="form-check-input" 
                                    v-model="editPoll.AutoCreateDiscussionArea">
                                Create Chat Room
                            </label>
                        </div>
                    </div>

                </div>

                <template v-if="editPoll.TypeCode == 'RegForm'">
                    <h6>Category Setup</h6>
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="form-floating mb-2" v-if="editPoll.TypeCode == 'RegForm'">
                                <select class="form-select form-select-sm" v-model="editPoll.AttendeeCategoryCode">
                                    <option selected disabled hidden :value="null">(Please Select...)</option>
                                    <option v-for="pt in show.AttendeeCategories" :value="pt.Code">
                                        {{pt.Name}} 
                                    </option>
                                </select>        
                                <label>
                                    Add Attendees to Category
                                </label>
                            </div>
                            <input class="form-control form-control-sm" v-model="editPoll.AttendeeCategoryCode">
                        </div>
                        <div class="col-xl-6">
                            <div class="form-floating mb-2" v-if="editPoll.TypeCode == 'RegForm'">
                                <select class="form-select form-select-sm" v-model="editPoll.VendorCategoryCode">
                                    <option selected :value="null">(Please Select...)</option>
                                    <option v-for="vendorCategory in show.VendorCategories" 
                                                :value="vendorCategory.Code">
                                            {{vendorCategory.Name}}
                                        </option>
                                </select>
                                <label>Page Primary Category</label>
                            </div>                
                        </div>
                    </div>
                </template>

                <h6>E-Mail Template Setup</h6>

                <div class="row">
                    <div class="col-xl-6">
                        <div class="form-floating mb-2">
                            <select class="form-select form-select-sm" v-model="editPoll.RegistrationNotificationTemplateCode">
                                <option selected :value="''">(Do not send an e-mail)</option>
                                <option v-for="pt in templates" :value="pt.Code">
                                    {{pt.Subject}} 
                                </option>
                            </select>       
                            <label>
                                Registration Received Template
                            </label>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="form-floating mb-2" v-if="editPoll.TypeCode == 'RegForm'">
                            <select class="form-select form-select-sm" v-model="editPoll.WelcomeEmailTemplateCode">
                                <option selected :value="''">(Do not send an e-mail)</option>
                                <option v-for="pt in templates" :value="pt.Code">
                                    {{pt.Subject}} 
                                </option>
                            </select>       
                            <label>
                                Individual Welcome Template
                            </label>
                        </div>
                    </div>
                </div>






                <div class="form-group mb-2">
                    <h6>
                        E-Mails to Notify when Submission Received
                    </h6>
                    <div>
                        <div class="row mb-1" v-for="(notifyRow, ix) in notifyUsers">
                            <div class="col">
                                <input placeholder="E-Mail Address" 
                                    class="form-control form-control-sm" 
                                    v-model="notifyRow.Email">
                            </div>
                            <div class="col-auto">
                                <div class="btn-group">
                                    <button @click="removeNotifyRow(ix)" type="button" class="btn btn-danger btn-sm">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                    <button @click="addNotifyRow" type="button" class="btn btn-success btn-sm">
                                        <i class="bi bi-plus"></i>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <button v-if="notifyUsers.length == 0" @click="addNotifyRow" type="button" class="btn btn-primary">Add</button>
                    </div>
                </div>

                <h6>Sharing Codes</h6>

                <div class="container-fluid">
                    <div class="row"
                        v-for="item in accessCodes">
                        <div class="col">
                            <input v-model="item.Code"
                                class="form-control-sm form-control">
                        </div>
                        <div class="col">
                            <input v-model="item.NewPassword"
                                   type="password"
                                   placeholder="Password"
                                   class="form-control-sm form-control">
                        </div>
                        <div class="col-auto">
                            <label class="form-check-label">
                                <input type="checkbox"
                                    v-model="item.IsActive"
                                    class="form-check-input">
                                Active
                            </label>
                        </div>
                        <div class="col-auto">
                            <div class="btn-group">
                                <button class="btn btn-sm btn-primary"
                                        @click="shareAccessCode(item)">
                                    <i class="bi bi-share"></i>
                                </button>
                                <button class="btn btn-sm btn-danger"
                                        @click="deleteAccessCode(item)">
                                    <i class="bi bi-trash"></i>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button type="button"
                                    class="btn btn-primary"
                                    @click="createAccessCode">
                                Add Sharing Code    
                            </button>
                            <button type="button"
                                    class="btn btn-primary"
                                    @click="saveAccessCodes">
                                Save
                            </button>

                        </div>
                    </div>
                </div>
                
                <h6>Conversion</h6>

                <div class="row">
                    <div class="col"
                        v-if="editPoll.TypeCode == 'Normal'">
                        <button type="button"
                                class="btn btn-warning"
                                @click="convertPollType('RegForm')">
                            Convert to Registration Form
                        </button>
                    </div>
                    <div class="col"
                        v-if="editPoll.TypeCode == 'RegForm'"
                        @click="convertPollType('Normal')">
                        <button type="button"
                                class="btn btn-warning">
                            Convert to Regular Poll    
                        </button>
                    </div>
                </div>
            </template>

            <template v-else-if="editorMode == 'questions'">
                <div class="row mb-2">
                    <div class="col">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle form-control" 
                                    type="button"
                                    data-bs-toggle="dropdown" 
                                    aria-expanded="false">
                                    Add Question...
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="questionTemplate in preciseTemplates">
                                    <button class="dropdown-item" 
                                            type="button"       
                                    @click="addQuestionPrecise(questionTemplate,0)">
                                    {{questionTemplate.Name}}
                                    </button>
                                </li>
                                <hr>
                                <li v-for="questionTemplate in questionTemplates">
                                    <button class="dropdown-item" 
                                            type="button"       
                                    @click="addQuestion(questionTemplate,0)">
                                    {{questionTemplate.Name}}
                                    </button>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>

                <vue-slicklist class="list-group" 
                            :useDragHandle="true"  
                            v-model="editPoll.Questions" 
                            lockAxis="y">


                    <vue-slickitem class="list-group-item" 
                                v-for="(question, i) in editPoll.Questions" 
                                :key="i" 
                                :index="i">
                        
                        <div>
                            <div class="d-flex">
                                <i class="bi-grip-vertical mt-2 me-1" v-handle></i>

                                <input  :disabled="submitting" 
                                        :placeholder="'Question Name'"
                                        v-if="question.QuestionType != 'Html'"
                                        class="form-control form-control-sm"
                                        :ref="`question_${i}`"
                                        @click="selectedQuestion = question"
                                        @focus="selectedQuestion = question"
                                        v-model="question.Name" />


                                <input v-else
                                    value="HTML Question"
                                    readonly="true"
                                    class="form-control form-control-sm"
                                    disabled>

                                <div class="btn-group ms-1">
                                    <button type="button" 
                                            class="btn btn-sm btn-secondary"
                                            @click="addQuestion(questionTemplates[0], i+1)">
                                        Add
                                    </button>
                                    <button type="button"
                                            class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split"
                                            :data-bs-toggle="`dropdown`">
                                        <span class="visually-hidden">
                                            Toggle Add New Poll Question
                                        </span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li v-for="questionTemplate in preciseTemplates">
                                            <button class="dropdown-item" 
                                                    type="button"       
                                            @click="addQuestionPrecise(questionTemplate,i+1)">
                                            {{questionTemplate.Name}}
                                            </button>
                                        </li>
                                        <hr>
                                        <li v-for="questionTemplate in questionTemplates">
                                            <button class="dropdown-item" 
                                                    type="button"       
                                            @click="addQuestion(questionTemplate,i+1)">
                                            {{questionTemplate.Name}}
                                            </button>
                                        </li>
                                    </ul>

                                </div>
                                <button type="button" class="btn btn-primary ms-1"
                                        @click.prevent="copyQuestionCode(question)"
                                        title="Copy question code">
                                        <i class="bi bi-clipboard pe-1"></i>
                                </button>

                                <button type="button" class="btn btn-danger ms-1"
                                        @click.prevent="deleteQuestion(question)">
                                        <i class="bi bi-trash pe-1"></i>
                                </button>
                            </div>


                            <div class="row mt-2"
                                v-if="question.QuestionType == 'TextPrompt'">
                                <div class="col">
                                    <div class="form-floating">
                                        <textarea v-model="question.Name" 
                                                class="form-control form-control-sm"
                                                style="height: 100px">
                                        </textarea>  
                                        <label>
                                            Contents
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col">
                                    <div class="form-floating">
                                        <input :disabled="submitting" 
                                            :placeholder="'Subtitle'"
                                            class="form-control form-control-sm"
                                            v-model="question.Notes" />

                                        <label>
                                            Subtitle
                                        </label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-floating">
                                        <input :disabled="submitting" 
                                            :placeholder="'Code'"
                                            class="form-control form-control-sm"
                                            v-model="question.Code" />
                                        <label>
                                            Code
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2">

                                <div class="col-auto">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" 
                                                type="checkbox" 
                                                v-model="question.IsMandatory">
                                            Required 
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input class="form-check-input" 
                                                type="checkbox" 
                                                v-model="question.WriteOnce">
                                            Write Once
                                        </label>
                                    </div>
                                </div>

                                <div class="col-auto">
                                    <div class="form-floating">

                                        <select v-model="question.QuestionType"
                                                class="form-select form-select-sm">
                                            <option selected :value="null">(Please Select...)</option>
                                            <option v-for="questionType in questionTypes" :value="questionType.Type">
                                                {{questionType.Name}}
                                            </option>
                                        </select>

                                        <label>
                                            Question Type
                                        </label>
                                    </div>
                                </div>

                                <div class="col-auto" 
                                    v-if="question.QuestionType == 'TextPrompt'">
                                    <div class="form-floating">
                                        <select v-model="question.LabelType"
                                                class="form-select form-select-sm"
                                                style="min-width: 150px">
                                            <option v-for="promptType in promptTypes"
                                                    :value="promptType.Type"
                                                    :key="promptType.Type">
                                                {{promptType.Name}}
                                            </option>
                                        </select>
                                        <label>Prompt Type</label>
                                    </div>
                                </div>

                                <div class="col-auto" 
                                    v-if="question.QuestionType == 'RankedChoice' || question.QuestionType=='Ranking'">
                                    <div class="form-floating">
                                        <select class="form-select form-select-sm" v-model="question.RankLimit">
                                            <option selected disabled hidden :value="null">(Please Select...)</option>
                                            <option v-for="i in rankLimit"
                                                    :key="i" 
                                                    :value="i">Top {{i}}</option>
                                        </select>
                                        <label>Rank Limit</label>
                                    </div>
                                </div>
                                <div class="col-auto" 
                                    v-if="question.QuestionType != 'TextPrompt' && question.QuestionType != 'Html' && question.QuestionType != 'GroupInsertButton'">
                                    <div class="form-floating">
                                        <select class="form-select form-select-sm" v-model="question.MappedTo" @change="changeQuestionBasedOnMappedToType(question)">
                                            <option selected :value="null">(Please Select...)</option>
                                            <optgroup v-for="type in mappedToTypes" :key="type.Name" :label="type.Name">
                                                <option v-for="map in type.Items" 
                                                        :value="map.Type">
                                                    {{map.Name}}
                                                </option>
                                            </optgroup>
                                        </select>
                                        <label>Mapped To...</label>
                                    </div>
                                </div>
                                <div class="col-6"
                                    v-if="question.QuestionType == 'SingleLineText'">
                                    <div class="form-floating">
                                        <select class="form-select form-select-sm"
                                                v-model="question.TextType">
                                            <option :value="null">
                                                Not Specified (Text)    
                                            </option>
                                            <option v-for="t in textTypes"
                                                    :key="t.Type"
                                                    :value="t.Type">
                                                {{ t.Name }}
                                            </option>        
                                        </select>
                                        <label>Input Type</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating">
                                        <select class="form-select form-select-sm" 
                                                v-model="question.ShowUntilResponseQuestionCode">
                                            <option :value="''">(Always Visible)</option>
                                            <option v-for="q in editPoll.Questions" 
                                                    :value="q.Code" 
                                                    v-if=" q.Code != question.Code"> 
                                                {{q.Name}} 
                                            </option>
                                        </select>
                                        <label>Hide unless the following question is answered:</label>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-floating">
                                        <input v-model="question.ReportingGroupCode"
                                               class="form-control form-control-sm">
                                        <label>For reporting, group by this code:</label>
                                    </div>
                                </div>

                                <div class="col-auto">
                                    <div class="form-floating">
                                        <input v-model="question.GroupTag"
                                            class="form-control form-control-sm"
                                            placeholder="Group Tag">
                                        <label>Group Tag</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2"
                                v-if="question.QuestionType == 'GroupInsertButton'">

                                <div class="col-auto">
                                    <div class="form-floating">
                                        <input type="number" 
                                            v-model="question.RankLimit" 
                                            value="0"
                                            class="form-control form-control-sm">
                                        <label>Group Limit</label>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="form-floating">
                                        <input type="text" 
                                            placeholder="Delete button Text" 
                                            v-model="question.Response"
                                            class="form-control form-control-sm">
                                        <label>Delete Button Text</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                
                                <div class="col"
                                    v-if="question.MappedTo == 'Website' || question.MappedTo == 'VENDOR_Website'">
                                    <input type="text" class="form-control form-control-sm" placeholder="Link Name..." v-model="question.Response2Placeholder">
                                    <input type="text" class="form-control form-control-sm" placeholder="Link Description..." v-model="question.Response3Placeholder">
                                    <input type="text" class="form-control form-control-sm" placeholder="Add to Section..." v-model="question.AddToSection">
                                </div>

                                <div class="col"
                                    v-if="(question.MappedTo == 'Downloadable' || question.MappedTo == 'VENDOR_Downloadable') 
                                    && question.QuestionType == 'LargeUpload'">
                                    <input type="text" class="form-control form-control-sm" placeholder="Download Name..." v-model="question.Response2Placeholder">
                                    <input type="text" class="form-control form-control-sm" placeholder="Download Description..." v-model="question.Response3Placeholder">
                                    <input type="text" class="form-control form-control-sm" placeholder="Add to Section..." v-model="question.AddToSection">
                                </div>
                            </div>


                            <template v-if="(question.QuestionType=='MultipleChoice'
                                            || question.QuestionType=='MultipleChoiceDropdown'
                                            || question.QuestionType=='MultipleChoiceMultiSelect')">

                                <div class="row mt-2">
                                    <div class="col-auto">
                                        <div class="btn-group">
                                            <button @click="addAnswer(question)" 
                                                    type="button"
                                                    class="btn btn-primary btn-sm">
                                                    <i class="bi bi-plus-square"></i>
                                            </button>
                                            <button  @click="clearAnswers(question)" 
                                                    type="button"
                                                    class="btn btn-warning btn-sm">
                                                    <i class="bi bi-trash"></i>
                                            </button>

                                        </div>
                                    </div>
                                    <div class="col">
                                        Options
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <vue-slicklist :useDragHandle="true" 
                                                v-model="question.Answers" 
                                                lockAxis="y">
                                        <vue-slickitem v-for="(answer, answerIndex) in question.Answers" 
                                                    :key="answerIndex" 
                                                    :index="answerIndex">
                                            <div class="d-flex p-0 mb-1">
                                                <i class="bi-grip-vertical" v-handle></i>
                                                <select class="form-select form-select-sm" v-model="answer.Response"
                                                        v-if="question.MappedTo == 'Category'"
                                                        @change="updateDisplayTextForCategory($event.target.value, answer)">
                                                    <option v-for="category in show.AttendeeCategories"
                                                            :value="category.Code"
                                                            :key="category.Code">
                                                        {{category.Name}}
                                                    </option>
                                                </select>

                                                <select v-if="question.MappedTo == 'Session'"
                                                        v-model="answer.Response"
                                                        @change="updateDisplayTextForSession(answer)"
                                                        class="form-select form-select-sm">
                                                    <option :value="''">
                                                        None
                                                    </option>
                                                    <optgroup v-for="(sessionCategory, code) in sessions"
                                                            :key="code"
                                                            :label="findCategory(code)">
                                                        <option v-for="session in sessionCategory"
                                                                :key="session.Code"
                                                                :value="session.Code">
                                                            {{ session.Name }} - {{ session.SessionStartDateTime | moment('YYYY-MM-DD hh:mm a') }}    
                                                        </option>
                                                    </optgroup>
                                                </select>

                                                
                                                <input  :disabled="submitting" 
                                                        :placeholder="'Answer Display Text'"
                                                        class="form-control form-control-sm"
                                                        v-if="question.MappedTo != 'Category'"
                                                        @change="onChange(question, answer, $event)"
                                                        @paste="onPaste(question, answer, answerIndex, $event)"
                                                        v-model="answer.DisplayText" />       
                                                
                                                <input  v-if="question.MappedTo != 'Category'"
                                                        :disabled="submitting" 
                                                        :placeholder="'Code'"
                                                        class="form-control form-control-sm"
                                                        v-model="answer.Response" />

                                                <div class="btn-group">
                                                    <button class="btn btn-outline-success btn-sm"
                                                            :class="{'active': answer.IsCorrect}"
                                                            @click="switchCorrectAnswer(question, answer)">
                                                        ✅
                                                    </button>
                                                    <button  @click="addAnswer(question,answerIndex)"
                                                            type="button"
                                                            class="btn btn-primary btn-sm">
                                                            <i class="bi bi-plus-square"></i>
                                                    </button>
                                                    <button @click="deleteAnswer(answerIndex,i)" type="button" class="btn btn-danger btn-sm" >
                                                        <i class="bi bi-dash-square"></i>
                                                    </button>
                                                </div>                                                    
                                            </div>
                                        </vue-slickitem>                                            
                                    </vue-slicklist>
                                </div>
                            </template>

                            <div class="row mt-2"
                            >
                                <div class="col-auto">
                                    <button @click="addCondition(question)" 
                                            type="button" 
                                            class="btn btn-primary btn-sm">
                                        <i class="bi bi-plus-square"></i>
                                    </button>
                                </div>
                                <div class="col">
                                    Display Conditions
                                </div>

                                    
                                <div class="col-auto">
                                    <div class="btn-group">
                                        <button type="button" 
                                                class="btn btn-sm btn-secondary"
                                                @click="addQuestion(questionTemplates[0], i+1)">
                                            Add Question
                                        </button>
                                        <button type="button"
                                                class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split"
                                                :data-bs-toggle="`dropdown`">
                                            <span class="visually-hidden">
                                                Toggle Add New Poll Question
                                            </span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li v-for="questionTemplate in preciseTemplates">
                                                <button class="dropdown-item" 
                                                        type="button"       
                                                @click="addQuestionPrecise(questionTemplate,i+1)">
                                                {{questionTemplate.Name}}
                                                </button>
                                            </li>
                                            <hr>
                                            <li v-for="questionTemplate in questionTemplates">
                                                <button class="dropdown-item" 
                                                        type="button"       
                                                @click="addQuestion(questionTemplate,i+1)">
                                                {{questionTemplate.Name}}
                                                </button>
                                            </li>
                                        </ul>

                                    </div>
                                </div>                                    
                            </div>

                            <template>
                                <div class="row mt-2"
                                    v-for="condition in question.Conditions">
                                    
                                    <div class="col">
                                        <div class="form-floating">
                                            <select class="form-select form-select-sm" 
                                                    v-model="condition.ReliesOnQuestionCode">
                                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                                <option v-for="q in editPoll.Questions" 
                                                        :value="q.Code" 
                                                        v-if=" q.Code != question.Code"> 
                                                    {{q.Name}} 
                                                </option>
                                            </select>
                                            <label>Show if...</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-floating"
                                            v-if="condition.ReliesOnQuestionCode && editPoll.Questions.find(x => x.Code == condition.ReliesOnQuestionCode)">
                                            <select class="form-select form-select-sm" 
                                                    v-model="condition.RequiredAnswer">
                                                <option selected :value="null">(Please Select...)</option>
                                                <option v-for="a in editPoll.Questions.find(x => x.Code == condition.ReliesOnQuestionCode).Answers" 
                                                        :value="a.Response">
                                                    {{a.DisplayText}}
                                                </option>
                                            </select>
                                            <label>...has answer:</label>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button @click="deleteCondition(condition,question)" 
                                                type="button"
                                                class="btn btn-danger btn-sm">
                                            <i class="bi bi-dash-square"></i>
                                        </button>                                        
                                    </div>


                                </div>
                            </template>
                        </div>
                    </vue-slickitem>
                </vue-slicklist>
            </template>
            </div>

            <div class="col">
                {{ previewUrl }}
                <div class="ratio ratio-1x1" style="height:100%">
                    <iframe :src="previewUrl" ref="previewer" @load="sendPreviewToDebugger">

                    </iframe>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import PollQuestion from '../../models/PollQuestion'
import PollQuestionAnswer from '../../models/PollQuestionAnswer'
import PollQuestionDisplayCondition from '../../models/PollQuestionDisplayCondition'
import Toastify from 'toastify-js'
import { debounce } from 'vue-debounce'


export default {
    props: ['poll', 'show'],
    mixins: [
        Token,
        Common
    ],

    watch:{
        'editPoll.AllowEditResponse' (to, from){
            if(to == true){
                this.editPoll.AllowMultiSubmit = false;
            }
        },

        'editPoll.AllowMultiSubmit'(to,from){
            if(to == true){
                this.editPoll.AllowEditResponse = false;
            }
        },

        editPoll: {
            deep: true,
            handler() {
                this.sendPreviewToDebugger();
            }
        }
    },

    data() {
        return {
            editorMode: 'details',

            showTab: "header",

            notifyUsers: [],
            selectedQuestion: null,
            templates: [],
            editPoll: null,

            validMandatory: true,
            validQuestionName: true,
            validQuestionType: true,
            validRankedQuestion: true,
            validRankedLimit:true,
            validAnswerName: true,
            validAnswerLength: true,
            validConditionQuestion: true,
            validConditionCode: true,
            
            rankLimit: 10,
            questionTypes:[
                {Type:"SingleLineText",Name:"Text"},
                {Type:"FreeformText",Name:"Text (Free-form)"},
                {Type:"MultipleChoice",Name:"Multiple Choice"},
                {Type:"MultipleChoiceMultiSelect",Name:"Multi-select"},
                {Type:"MultipleChoiceDropdown",Name:"Dropdown"},
                {Type:"RankedChoice",Name:"Ranked Choice"},
                {Type:"Ranking",Name:"Stars"},
                {Type:"NPS", Name: "Net Promoter Score"},
                {Type:"TextPrompt",Name:"Message"},
                {Type:"ImageUpload",Name:"Image Upload"},
                {Type:"LargeUpload",Name:"Generic Upload"},
                {Type:"GroupInsertButton",Name:"Group Insert Button"},
                {Type:"Html",Name:"HTML"},
                {Type:"INTERNAL",Name:"Hidden (Cannot be edited by user)"},
            ],
            textTypes:[
                {Type:"date",Name:"Date"},
                {Type:"email",Name:"E-Mail Address"},
                {Type:"tel",Name:"Telephone Number"},
                {Type:"text",Name:"Regular Text"}
            ],
            promptTypes:[
                {Type:"Normal",Name:"Normal",Style:"btn-light"},
                {Type:"Alert",Name:"Alert",Style:"btn-danger"},
                {Type:"Information",Name:"Information",Style:"btn-primary"},
                {Type:"Warning",Name:"Warning",Style:"btn-warning"},
                {Type:"Header1",Name:"Header1",Style:"btn-info"},
                {Type:"Header2",Name:"Header2",Style:"btn-info"},
                {Type:"Header3",Name:"Header3",Style:"btn-info"},
                {Type:"Header4",Name:"Header4",Style:"btn-info"},
                {Type:"Small",Name:"Small",Style:"btn-light"},
                {Type:"Generic",Name:"Generic div",Style:"btn-light"},
            ],
            mappedToTypes:[  
                {
                    Name: 'Attendee Fields',
                    Items: [
                        {Type:"FirstName",Name:"First Name"},
                        {Type:"LastName",Name:"Last Name"},
                        {Type:"Title",Name:"Title"},
                        {Type:"SubTitle",Name:"Sub Title"},
                        {Type:"Email",Name:"Primary Email"},
                        {Type:"Bio",Name:"Bio"},
                        {Type:"Category",Name:"Attendee Category"},
                        {Type:"FeaturedAttendee", Name:"Featured Attendee"},
                        {Type:"PhotoUrl", Name: "Photo"},
                        {Type:"CoverPhotoUrl", Name: "Cover Photo"},
                        {Type:"Website", Name: "Website"},
                        {Type:"Session", Name: "Sessions"},
                        {Type:"Downloadable", Name:"Download"},
                        {Type:"CustomCategory", Name:"Custom Category"},
                        {Type:"CustomCategoryHidden", Name:"Custom Category(Hidden)"}
                    ]
                },
                {
                    Name: 'Vendor Fields',
                    Items: [
                        {Type:"VENDOR_Name",Name:"Name"},
                        {Type:"VENDOR_Bio",Name:"Bio"},
                        {Type:"VENDOR_Headline",Name:"Headline"},
                        {Type:"VENDOR_Website", Name: "Website"},
                        {Type:"VENDOR_PhotoUrl", Name: "Photo"},
                        {Type:"VENDOR_CoverPhotoUrl", Name: "Cover Photo"},
                        {Type:"VENDOR_ListPhotoUrl", Name: "List Item Photo"},
                        {Type:"VENDOR_Downloadable", Name: "Download"},
                        {Type:"VENDOR_IFrameUrl", Name: "Cover IFrame URL"}
                    ]
                },
                {
                    Name: 'Other Fields',
                    Items: [
                        {
                            Type: 'CONTACT_Name',
                            Name: 'Respondant Name'
                        },
                        {
                            Type: 'CONTACT_Email',
                            Name: "Respondant E-Mail"
                        },
                        {
                            Type: "CONTACT_RegCode",
                            Name: "Unique Registration Code"
                        }
                    ]
                }
            ],
            uploadBus: new Vue(),
            sessionSelected: null,
            questionTemplates: [],
            preciseTemplates: [],
            sessions:[],

            currentDebug: '',
            showQuestionSelector: false,
            questionSelectorX: -1,
            questionSelectorY: 160,
            selectedQuestionIndex: 0,
            accessCodes: [],
        }
    },

    created: function() {
        console.log(this.mappedToTypes);
        this.preparePollForEdit();
        this.populateQuestionTemplates();
        this.populateSessions();
        this.getAccessCodes();

        window.addEventListener('paste', this.onPasteWindow);
    },

    beforeDestroy() {
        window.removeEventListener('paste', this.onPasteWindow);
    },

    computed: {
        previewUrl() {
            return `${process.env.VUE_APP_POLL_APP_ENDPOINT}/#/pp/${this.show.Code}/____DEBUG?preview=true`
        },

        questionSelectorStyle() {
            return `position: absolute; top: ${this.questionSelectorY}px; left; 
                ${this.questionSelectorX}px; width: 200px; height: 200px;`;
        }
    },

    methods: {
        exportToClipboard() {
            let json = JSON.stringify(this.editPoll);
            navigator.clipboard.writeText(json);

            Toastify({
                text: "Copied poll JSON to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        async importFromClipboard() {
            try {
                let text = await navigator.clipboard.readText();
                let json = JSON.parse(text);

                json.ShowCode = this.show.Code;
                json.Id = 0;
                json.Code = this.createUniqueCode();

                this.editPoll = json;

                Toastify({
                    text: "Imported poll from clipboard.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();

            } catch(ex) {
                console.error("Error on import - ", ex);
            }

        },

        switchCorrectAnswer(question, answer) {
            for(let i = 0; i < question.Answers.length; i++) {
                if(question.Answers[i] == answer) {
                    continue;
                }
                Vue.set(question.Answers[i], 'IsCorrect', false);
            }

            answer.IsCorrect = !answer.IsCorrect;            
        },

        onPasteWindow(e) {
            let activeElement = document.activeElement;

            if(activeElement && activeElement.tagName == 'INPUT') {
                return;
            }

            if(activeElement && activeElement.tagName == "TEXTAREA") {
                return;
            }

            let clipboardData = e.clipboardData || window.clipboardData;

            if(!clipboardData) {
                return;
            }

            let pastedContent = clipboardData.getData("text");

            e.preventDefault();
            console.log(pastedContent);

            let lines = pastedContent.split('\n').map(line => line.trim()).filter(line => line);

            const processedLines = lines.map(line => {
                // Remove numbers at the start of the line
                line = line.replace(/^\s*\d+\.\s*/, '');

                // Remove anything in brackets at the end of the line
                line = line.replace(/\s*\(.*\)\s*$/, '');

                return line.trim();
            });


            console.log(processedLines);

            for(let i = 0; i < processedLines.length; i++) {

                let question = this.addQuestion(this.questionTemplates[0], this.editPoll.Questions.length);
                question.Name = processedLines[i];
                question.QuestionType = 'MultipleChoice';

                let originalLine = lines[i];

                if(originalLine.indexOf("(T/F)") != -1) {
                    let trueQ = new PollQuestionAnswer();
                    trueQ.DisplayText = "True";
                    trueQ.Response = "True";
                    question.Answers.push(trueQ);

                    trueQ = new PollQuestionAnswer();
                    trueQ.DisplayText = "False";
                    trueQ.Response = "False";
                    question.Answers.push(trueQ);

                }

                if(originalLine.indexOf("(V/F)") != -1) {
                    let trueQ = new PollQuestionAnswer();
                    trueQ.DisplayText = "Vrai";
                    trueQ.Response = "Vrai";
                    question.Answers.push(trueQ);

                    trueQ = new PollQuestionAnswer();
                    trueQ.DisplayText = "Faux";
                    trueQ.Response = "Faux";
                    question.Answers.push(trueQ);


                }
            }
            
        },

        clearAnswers(question) {
            question.Answers = [];
        },

        async onPaste(question, answer, ix, e) {
            e.preventDefault();

            console.log("Got a paste event!", e, question, answer);
            let data = await navigator.clipboard.readText();

            if(data.indexOf("\n") == -1) {
                console.log("No new line.");
                answer.DisplayText = data;
                answer.Response = data;
                return true;
            }

            let options = data.replace(/\r/g, '').split("\n").filter(x => x && x.trim().length > 0);
            console.log("Options:", options);
            let currentIndex = ix;

            for(let i = 0; i < options.length; i++) {

                if(currentIndex >= question.Answers.length) {
                    this.addAnswer(question);
                }

                let answer = question.Answers[currentIndex];
                
                let option = options[i];
                let displayText = option;
                let response = option;

                if(option.indexOf("\t") != -1) {
                    let split = option.split("\t");

                    response = split[split.length - 1];
                }

                answer.DisplayText = displayText;
                answer.Response = response;
                currentIndex++;
            }

            console.log("Data:", data, "Options: ", options);
            return false;
        },

        onChange(question, answer, e) {
            console.log("Got a change event!", e, question, answer);
            let $event = e;

            (question.MappedTo != 'Session') 
                ? answer.Response = $event.target.value 
                : answer.Response = answer.Response; 
        },

        onTextAreaKeyDown(e) {
            let target = e.target;

            if(e.keyCode === 9) {
                e.preventDefault();

                target.setRangeText('  ', target.selectionStart, target.selectionStart, 'end')
                return false;
            }
        },

        sendPreviewToDebugger(){
            console.log(this.$refs.previewer.contentWindow.postMessage);
            this.$refs.previewer.contentWindow.postMessage({
                poll: this.editPoll,
                show: this.show
            }, "*");
        },

        shareAccessCode(item) {
            navigator.clipboard.writeText(this.pollResultLink(item));
            Toastify({
                text: "Copied public poll result link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        pollResultLink: function(accessCodeModel) {
            return `${window.location.protocol}//${window.location.host}/#/pollresults/${this.show.Code}/${this.editPoll.Code}/${accessCodeModel.Code}`;
        },


        async getAccessCodes() {
            let result = await this.tryGet(`/api/pollpermissioncodes/${this.show.Code}/${this.editPoll.Code}`);

            this.accessCodes = result.Result;
        },

        createAccessCode() {
            this.accessCodes.push({
                _IsNew: true,
                Code: this.createUniqueCode(),
                ShowCode: this.show.Code,
                PollCode: this.editPoll.Code,
                IsActive: true
            });
        },

        async saveAccessCodes() {
            this.submitting = true;

            await this.tryPost(`/api/pollpermissioncodes`, JSON.stringify(this.accessCodes), 'application/json');

            await this.getAccessCodes();

            this.submitting = false;
        },

        async deleteAccessCode(accessCode) {
            if(accessCode._IsNew) {
                let index = this.accessCodes.indexOf(accessCode);
                if(index == -1) {
                    return;
                }

                this.accessCodes.splice(index, 1);
                return;
            }
            this.submitting = true;
            await this.tryDelete(`/api/pollpermissioncodes/${this.show.Code}/${this.editPoll.Code}/${accessCode.Code}`);

            await this.getAccessCodes();
            this.submitting = false;
        },

        async convertPollType(newTypeCode) {
            let confirmResult = confirm("Are you sure you want to convert this poll?");

            if(!confirmResult) {
                return;
            }

            this.editPoll.TypeCode = newTypeCode;
            await this.onSave();

            this.$router.replace(`/shows/${this.show.Code}/${newTypeCode == 'Normal' ? 'polls' : 'registration'}`);
        },

        findCategory(c) {
            let category = this.show.SessionCategories.find(x => x.Code == c);

            return category?.Name || 'NO NAME FOUND';
        },

        cancelEnter(e) {
            if(e.which === 13) {
                e.preventDefault();
            }
        },

        onKeyUpMappingEditor(ev, mapping) {
            let currentResponse = ev.srcElement.innerText;
            const selection = document.getSelection();
            let position = document.getSelection().anchorOffset;
            console.log(ev);

            if(ev.code == 'Enter') {
                ev.preventDefault();
            }

            if(ev.code == 'Escape') {
                this.showQuestionSelector = false;
                this.selectedQuestionIndex = 0;
                return;
            }

            let outputToDebug = `Text: ${currentResponse}\r\nPosition: ${position}`;

            if(position >= 2) {
                let lastTwoChara = currentResponse.substr(position - 2, position);
                outputToDebug += `\r\n${lastTwoChara}`

                if(lastTwoChara == "{{") {
                    this.showQuestionSelector = true;

                    let positionRect = ev.srcElement.getBoundingClientRect();
                    this.questionSelectorY = positionRect.top + window.scrollY + positionRect.height;
                    this.questionSelectorX = positionRect.left + window.scrollX;
                    
                    //outputToDebug += `\r\nPosition: ${rect.left}, ${rect.top}`

                } else {
                    this.showQuestionSelector = false;
                }
            }

            if(this.showQuestionSelector 
                && ev.code == 'ArrowDown'
                || ev.code == 'ArrowUp') {
                let direction = ev.code == "ArrowDown"
                    ? 1
                    : ev.code == "ArrowUp"
                        ? -1
                        : 0;

                if(direction != 0) {
                    let newIndex = this.selectedQuestionIndex + direction;

                    if(newIndex == -1) {
                        newIndex = this.editPoll.Questions.length - 1;
                    } else if(newIndex >= this.editPoll.Questions.length) {
                        newIndex = 0;
                    }

                    this.selectedQuestionIndex = newIndex;

                    let selectedItem = this.$refs[`question_${this.selectedQuestionIndex}`][0];

                    selectedItem.scrollIntoView()
                }
            } else if(this.showQuestionSelector 
                && ev.code == 'Enter') {
                
                ev.srcElement.innerText += this.editPoll.Questions[this.selectedQuestionIndex].Code + "}}";

                this.showQuestionSelector = false;
                this.selectedQuestionIndex = 0;

                let range = document.createRange();
                selection.removeAllRanges();
                range.selectNodeContents(ev.srcElement);
                range.collapse(false);
                selection.addRange(range);

            }

            outputToDebug += `\r\nShow Selector: ${this.showQuestionSelector}`

            this.currentDebug = outputToDebug;

            // TODO: this is where you would want to assign the current editor text
            // to the passed in model.
        },

        changeQuestionBasedOnMappedToType(question){
            switch(question.MappedTo){
                case 'FirstName':
                case 'LastName':
                case 'Email':
                case 'Title':
                case 'SubTitle':
                case 'VENDOR_Name':
                case 'Website':
                case 'VENDOR_Website':
                    question.QuestionType = "SingleLineText";
                    break;
                case 'Bio':
                case 'VENDOR_Bio':
                    question.QuestionType = "FreeformText";
                    break;
                case 'PhotoUrl':
                case 'VENDOR_PhotoUrl':
                case 'CoverPhotoUrl':
                case 'VENDOR_CoverPhotoUrl':
                case 'VENDOR_ListPhotoUrl':
                    question.QuestionType = "ImageUpload";
                    break;
                case 'Downloadable':
                case 'VENDOR_Downloadable':
                    question.QuestionType = "LargeUpload";
                default:
            }
        },

        async populateSessions(){
            try {
                let r = await this.tryGet(`/api/show/${this.show.Code}/sessions`);
                this.sessions = r.Result;

            } catch(ex) {
                console.error(ex);
            }
            
        },
        
        populateQuestionTemplates(){
            let pollQuestion = new PollQuestion();
            
            //Blank
            pollQuestion.Name = "Blank Question";
            this.questionTemplates.push(pollQuestion);

            //Multiple Choice
            pollQuestion = new PollQuestion();
            let pollAnswers = [];
            let pollAnswer = new PollQuestionAnswer();
            pollQuestion.Name = "Multiple Choice";
            pollQuestion.QuestionType = "MultipleChoice";
            pollAnswers.push(pollAnswer);
            pollAnswers.push(pollAnswer);
            pollAnswers.push(pollAnswer);
            pollQuestion.Answers = pollAnswers;
            this.questionTemplates.push(pollQuestion);

            //Yes No Template
            pollQuestion = new PollQuestion();
            pollAnswers = [];

            pollQuestion.Name = "Yes/No";
            pollQuestion.QuestionType = "MultipleChoice";
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Yes";
            pollAnswer.Response = "Yes";
            pollAnswers.push(pollAnswer);

            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "No";
            pollAnswer.Response = "No";
            pollAnswers.push(pollAnswer);

            pollQuestion.Answers = pollAnswers;
            this.questionTemplates.push(pollQuestion);

            //T/F Template
            pollQuestion = new PollQuestion();
            pollAnswers = [];

            pollQuestion.Name = "True/False (EN)";
            pollQuestion.QuestionType = "MultipleChoice";
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "True";
            pollAnswer.Response = "True";
            pollAnswers.push(pollAnswer);

            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "False";
            pollAnswer.Response = "False";
            pollAnswers.push(pollAnswer);

            pollQuestion.Answers = pollAnswers;
            this.questionTemplates.push(pollQuestion);            

            //T/F Template
            pollQuestion = new PollQuestion();
            pollAnswers = [];

            pollQuestion.Name = "True/False (FR)";
            pollQuestion.QuestionType = "MultipleChoice";
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Vrai";
            pollAnswer.Response = "Vrai";
            pollAnswers.push(pollAnswer);

            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Faux";
            pollAnswer.Response = "Faux";
            pollAnswers.push(pollAnswer);

            pollQuestion.Answers = pollAnswers;
            this.questionTemplates.push(pollQuestion);            

            //Likert Scale
            pollQuestion = new PollQuestion();
            pollAnswers = [];

            pollQuestion.Name = "Likert Scale";
            pollQuestion.QuestionType = "MultipleChoice";
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Strongly Agree";
            pollAnswer.Response = "Strongly Agree";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Agree";
            pollAnswer.Response = "Agree";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Neutral";
            pollAnswer.Response = "Neutral";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Disagree";
            pollAnswer.Response = "Disagree";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Strongly Disagree";
            pollAnswer.Response = "Strongly Disagree";
            pollAnswers.push(pollAnswer);

            pollQuestion.Answers = pollAnswers;
            this.questionTemplates.push(pollQuestion);

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "Five Stars";
            pollQuestion.QuestionType = "Ranking";
            pollQuestion.RankLimit = 5;
            this.questionTemplates.push(pollQuestion);


            pollQuestion = new PollQuestion();
            pollAnswers = [];

            pollQuestion.Name = "Satisfaction";
            pollQuestion.QuestionType = "MultipleChoice";
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Very Satisfied";
            pollAnswer.Response = "Very Satisfied";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Satisfied";
            pollAnswer.Response = "Satisfied";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Dissatisfied";
            pollAnswer.Response = "Dissatisfied";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Very Dissatisfied";
            pollAnswer.Response = "Very Dissatisfied";
            pollAnswers.push(pollAnswer);
            pollAnswer = new PollQuestionAnswer();
            pollAnswer.DisplayText = "Not Applicable";
            pollAnswer.Response = "Not Applicable";
            pollAnswers.push(pollAnswer);

            pollQuestion.Answers = pollAnswers;
            this.questionTemplates.push(pollQuestion);


            // First Name
            pollQuestion = new PollQuestion();
            pollQuestion.Name = "First Name";
            pollQuestion.QuestionType = "SingleLineText";
            pollQuestion.IsMandatory = true;
            pollQuestion.Code = "FN";
            pollQuestion.MappedTo = "FirstName";
            this.preciseTemplates.push(pollQuestion);

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "Last Name";
            pollQuestion.QuestionType = "SingleLineText";
            pollQuestion.IsMandatory = true;
            pollQuestion.Code = "LN";
            pollQuestion.MappedTo = "LastName";
            this.preciseTemplates.push(pollQuestion);

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "E-Mail Address";
            pollQuestion.QuestionType = "SingleLineText";
            pollQuestion.IsMandatory = true;
            pollQuestion.Code = "EMAIL";
            pollQuestion.MappedTo = "Email";
            this.preciseTemplates.push(pollQuestion);


            pollQuestion = new PollQuestion();
            pollQuestion.Name = "Company";
            pollQuestion.QuestionType = "SingleLineText";
            pollQuestion.IsMandatory = true;
            pollQuestion.Code = "COMPANY";
            pollQuestion.MappedTo = "SubTitle";
            this.preciseTemplates.push(pollQuestion);            

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "Entry Tactic";
            pollQuestion.QuestionType = "INTERNAL";
            pollQuestion.IsMandatory = false;
            pollQuestion.Code = "TACTIC";
            this.preciseTemplates.push(pollQuestion);            

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "ParticipantCode";
            pollQuestion.QuestionType = "INTERNAL";
            pollQuestion.IsMandatory = false;
            pollQuestion.Code = "PARTICIPANT";
            this.preciseTemplates.push(pollQuestion);      

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "SessionCode";
            pollQuestion.QuestionType = "INTERNAL";
            pollQuestion.IsMandatory = false;
            pollQuestion.Code = "SESSION";
            this.preciseTemplates.push(pollQuestion);      

            pollQuestion = new PollQuestion();
            pollQuestion.Name = "LoginInstance";
            pollQuestion.QuestionType = "INTERNAL";
            pollQuestion.IsMandatory = false;
            pollQuestion.Code = "LOGIN";
            this.preciseTemplates.push(pollQuestion);   

        },
        async preparePollForEdit() {
            this.editPoll = this.poll;

            if(!this.editPoll.RedirectToUrl) {
                Vue.set(this.editPoll, 'RedirectToUrl', null);
            }

            if(!this.editPoll.SubmitButtonText) {
                Vue.set(this.editPoll, 'SubmitButtonText', null);
            }

            if(!this.editPoll.WelcomeEmailTemplateCode) {
                Vue.set(this.editPoll, 'WelcomeEmailTemplateCode', null);
            }

            if(!this.editPoll.AutoCreateDiscussionArea) {
                Vue.set(this.editPoll, "AutoCreateDiscussionArea", false);
            }

            if(!this.editPoll.ClosedMessage) {
                Vue.set(this.editPoll, 'ClosedMessage', '');
            }

            this.editPoll.Questions.forEach(q => {
                if(!q.LiveCategoryCode) {
                    Vue.set(q, 'LiveCategoryCode', '');
                }
            });

            if(!this.editPoll.LandingPageMessage) {
                Vue.set(this.editPoll, 'LandingPageMessage', '');
            }

            if(!this.editPoll.FooterContent) {
                Vue.set(this.editPoll, 'FooterContent', '');
            }

            this.editPoll.Responses = [];

            if(!this.editPoll.TypeCode || this.editPoll.TypeCode == "")
            {
                this.editPoll.TypeCode ='Normal';
            }

            this.uploadBus.$on('uploadSuccess', r => {
                this.editPoll.HeaderImage = r.Result;
            });

            this.submitting = true;

            try {
                let r = await this.tryGet('/api/communications/templates/' + this.show.Code);
                this.templates = r.Result;

                if(this.editPoll.Id != '0') {
                    r = await this.tryGet(`/api/pollnotify/${this.editPoll.ShowCode}/${this.editPoll.Code}`);

                    if(r.Result && r.Result.length > 0) {
                        this.notifyUsers = r.Result;
                    } 
                }

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;

        },

        removeNotifyRow(ix) {
            this.notifyUsers.splice(ix, 1);
        },

        addNotifyRow() {
            this.notifyUsers.push({
                Email: ''
            });
        },
        
        updateDisplayTextForCategory(categoryCode, answer) {
            if(!categoryCode) {
                answer.DisplayText = "";
            } else {
                let category = this.show.AttendeeCategories.find(x=>x.Code == categoryCode);
                if(!category) {
                    answer.DisplayText = '';
                } else {
                    answer.DisplayText = category.Name;
                }
            }
        },

        updateDisplayTextForSession(answer) {

            Object.keys(this.sessions).forEach(k => {
                let sessionsInCategory = this.sessions[k];

                let session = sessionsInCategory.find(x => x.Code == answer.Response);

                if(session) {
                    answer.DisplayText = session.Name;
                }
            });
        },        

        updateReliesOnCode(pollQuestions, oldCode, newCode){
            for(var pq of pollQuestions){
                if(pq.Code == oldCode || pq.Code == newCode){
                    continue;
                }
                if(pq.Conditions && pq.Conditions.length > 0){
                    for(var condition of pq.Conditions){
                        if(condition.ReliesOnQuestionCode == oldCode){
                            condition.ReliesOnQuestionCode = newCode;
                        }
                    }
                }
            }
        },

        validateForm: function() {
            this.validateErrors = [];

            this.validQuestionName = true;
            this.validQuestionType = true;
            this.validRankedQuestion = true;
            this.validRankedLimit = true;
            this.validAnswerName = true;
            this.validAnswerResponse = true;
            this.validAnswerLength = true;
            this.validConditionQuestion = true;
            this.validConditionCode = true;
            this.validRanking = true;
            
            this.validMandatory = true;
            

            if(this.editPoll.Questions.length > 0){
                for(var i = 0; i <this.editPoll.Questions.length; i++){

                    let questionToCheck = this.editPoll.Questions[i];

                    if(questionToCheck.MappedTo == "Session") {

                        questionToCheck.Answers.forEach(a => {
                            let sessionCode = a.Response;

                            
                        });

                    }


                    if(this.editPoll.TypeCode ==='RegForm'){
                        var first = false;
                        var last = false;
                        var title = false;
                        var subtitle = false;
                        var email = false;
                        var biography = false;
                        if(this.editPoll.Questions[i].MappedTo==='FirstName'){
                            if(!first){
                                first = true;
                            }
                            else{
                                this.validMappedTo = false;
                            }

                        }
                        else if(this.editPoll.Questions[i].MappedTo==='LastName'){
                            if(!last){
                                last = true;
                            }
                            else{
                                this.validMappedTo = false;
                            }

                        }
                        else if(this.editPoll.Questions[i].MappedTo==='Title'){
                            if(!title){
                                title = true;
                            }
                            else{
                                this.validMappedTo = false;
                            }
                        }
                        else if(this.editPoll.Questions[i].MappedTo==='SubTitle'){
                            if(!subtitle){
                                subtitle = true;
                            }
                            else{
                                this.validMappedTo = false;
                            }

                        }
                        else if(this.editPoll.Questions[i].MappedTo==='Email'){
                            if(!email){
                                email = true;
                            }
                            else{
                                this.validMappedTo = false;
                            }

                        }
                        else if(this.editPoll.Questions[i].MappedTo==='Biography'){
                            if(!biography){
                                biography = true;
                            }
                            else{
                                this.validMappedTo = false;
                            }

                        }
                    }

                    if(this.editPoll.Questions[i].QuestionType == 'TextPrompt' && this.editPoll.Questions[i].IsMandatory){
                        this.validMandatory = false;
                    }

                    if(!this.editPoll.Questions[i].Name){
                        this.validQuestionName = false;
                    }
                    if(!this.editPoll.Questions[i].QuestionType){
                        this.validQuestionType=false;
                    }

                    if(this.editPoll.Questions[i].QuestionType === 'Ranking' && this.editPoll.Questions[i].RankLimit ===  0){
                        this.validRanking = false;
                    }
                    if((this.editPoll.Questions[i].QuestionType === 'MultipleChoice' 
                        || this.editPoll.Questions[i].QuestionType === 'MultipleChoiceDropDown'
                        || this.editPoll.Questions[i].QuestionType === 'MultipleChoiceMultiSelect') 
                        && this.editPoll.Questions[i].Answers.length === 0
                        && this.editPoll.Questions[i].MappedTo != 'Session')
                    {              
                        this.validAnswerLength = false;
                    }

                    for(var j = 0; j < this.editPoll.Questions[i].Answers.length; j++){
                        if(!this.editPoll.Questions[i].Answers[j].DisplayText){
                            this.validAnswerName = false;
                        }
                        if(!this.editPoll.Questions[i].Answers[j].Response){
                            this.validAnswerResponse = false;
                        }
                    }
                    if(this.editPoll.Questions[i].Conditions){
                        for(var k = 0; k < this.editPoll.Questions[i].Conditions.length; k++){
                            if(!this.editPoll.Questions[i].Conditions[k].RequiredAnswer){
                                this.validConditionQuestion = false;
                            }
                            if(this.editPoll.Questions[i].Conditions[k].ReliesOnQuestionCode === 0){
                                this.validConditionCode = false;
                            }
                        }
                    }
                }
            }
            
            var str = this.editPoll.Name;
            if (this.editPoll.Name && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Name cannot be only spaces.");
            }
            if(!this.editPoll.Name) {
                this.validateErrors.push("Name cannot be blank.");
            }
            if(!this.editPoll.Code) {
                this.validateErrors.push("Code cannot be blank.");
            }
            
            if(!this.validMandatory){
                this.validateErrors.push("Message can't be mandatory");
            }

            if(!this.validRanking){
                this.validateErrors.push("Stars must have at least have one star");
            }

            if(this.editPoll.Questions.length == 0) {
                this.validateErrors.push("Please add at least 1 question.");
            }

            if(!this.validQuestionType){
                this.validateErrors.push("Please select a question type.");
            }
            if(!this.validRankedLimit){
                 this.validateErrors.push("Please select a rank limit.");
            }
            if(!this.validAnswerLength){
                this.validateErrors.push("Multiple Choice, Multi Select and Ranked needs at least 1 answer.");
            }   
            if(!this.validRankedQuestion){
                 this.validateErrors.push("Rank limit must be less than or equal to the number of answers.");
            }
            //todo look into this
            // if(!this.validAnswerResponse){
            //     this.validateErrors.push("At least 1 answer has a blank response code.")
            // } 
            if(!this.validConditionQuestion){
                this.validateErrors.push("At least 1 question has a display condition without an answer selected.");
            }
            if(!this.validConditionCode){
                this.validateErrors.push("At least 1 question has a blank display condition.");
            }
            //Any more form errors??
            return this.validateErrors.length === 0;
        },
        
        addCondition(question){
            var pqdc = new PollQuestionDisplayCondition();
            var qindex = this.editPoll.Questions.indexOf(question);

            if(this.editPoll.Questions[qindex].Conditions == null){
                this.editPoll.Questions[qindex].Conditions = []
            }

            this.editPoll.Questions[qindex].Conditions.push(pqdc);
        },

        deleteCondition(condition,question){
            var qindex = this.editPoll.Questions.indexOf(question);
            var cindex = this.editPoll.Questions[qindex].Conditions.indexOf(condition)
            this.editPoll.Questions[qindex].Conditions.splice(cindex,1)
        },

        deleteAnswer(answerIndex,questionIndex){
            this.editPoll.Questions[questionIndex].Answers.splice(answerIndex,1);
        },

        addAnswer(question,index){
            var pqa = new PollQuestionAnswer();
            var qindex = this.editPoll.Questions.indexOf(question);

            if(question.MappedTo == 'Sessions'){
                if(this.sessionSelected == null){
                    return;
                }
                if(question.Answers.some(x => x.Response == this.sessionSelected.Code)){
                    return;
                }    
                pqa.DisplayText = this.sessionSelected.Name;
                pqa.Response = this.sessionSelected.Code;    
            }

            if(this.editPoll.Questions[qindex].Answers === null){
                this.editPoll.Questions[qindex].Answers = []
            }

            if(!index){
                this.editPoll.Questions[qindex].Answers.push(pqa);
            }
            else{
                this.editPoll.Questions[qindex].Answers.splice(index,0,pqa);
            }
            
        },

        copyQuestionCode(question) {
            navigator.clipboard.writeText(`?qrr=${question.Code}:VALUE`);
        },

        deleteQuestion(question){
            var index = this.editPoll.Questions.indexOf(question)
            let questionCode = question.Code;

            for(var j = 0; j < this.editPoll.Questions.length; j++){
                if(j == index){
                    continue;
                }

                var question = this.editPoll.Questions[j];

                if(question.Conditions && question.Conditions.length > 0){

                    for(var k = 0; k < question.Conditions.length; k++){

                        var condition = question.Conditions[k];
                        if(condition.ReliesOnQuestionCode == questionCode){
                            question.Conditions.splice(k,1);
                        }

                    }
                }
            }

            this.editPoll.Questions.splice(index,1);
        },

        addQuestion(template, index){
            let questionToAdd = this.cloneObject(template);
            questionToAdd.Name = "";


            let currentLength = this.editPoll.Questions.length + 1;
            let candidateCode = `Q${currentLength}`;

            if(this.editPoll.Questions.find(x => x.Code == candidateCode)) {
                candidateCode = this.createUniqueCode();
            }

            questionToAdd.Code = candidateCode;

            this.editPoll.Questions.splice(index, 0,questionToAdd);
            this.selectedQuestion = this.editPoll.Questions[index];

            Vue.nextTick(() => {
                this.$refs[`question_${index}`][0].focus();
            });

            return questionToAdd;
        },

        addQuestionPrecise(template, index){
            let questionToAdd = this.cloneObject(template);
            this.editPoll.Questions.splice(index, 0,questionToAdd);
            this.selectedQuestion = this.editPoll.Questions[index];

            Vue.nextTick(() => {
                this.$refs[`question_${index}`][0].focus();
            });

            return questionToAdd;
        },


        async onSave() {
            this.submitting = true;
       
            if(this.editPoll.TypeCode==='RegForm'){
                this.editPoll.AllowAnonymousResponse = true;
            }

            //Reset defaults             
            for(var i = 0;i < this.editPoll.Questions.length;i++){
                var question = this.editPoll.Questions[i];

                if(!question.Answers
                    || !question.Answers.length) {
                    question.Answers = [];
                    continue;
                }

                for(var j = 0;j<question.Answers.length;j++){
                    var answer = question.Answers[j];
                    answer.Response = answer.Response;
                }
            }
            
            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }
            this.editPoll.Code = this.editPoll.Code.toUpperCase();
            //Reset defaults depending on QuestionType
            for(var i = 0; i < this.editPoll.Questions.length; i++){
                var question = this.editPoll.Questions[i]

                if(question.QuestionType == 'MultipleChoice'
                || question.QuestionType == 'MultipleChoiceDropdown'
                || question.QuestionType == 'MultipleChoiceMultiSelect'){
                    question.TextType = null;
                    question.LabelType = null;
                    question.RankLimit = 0;
                }
                else if(question.QuestionType == 'RankedChoice'){
                    question.TextType = null;
                    question.LabelType = null;
                }
                else if(question.QuestionType == 'TextPrompt'){
                    question.Answers = [];
                    question.TextType = null;
                    question.RankLimit = 0;
                }
                else if(question.QuestionType == 'SingleLineText'){
                    question.Answers = [];
                    question.LabelType = null;
                    question.RankLimit = 0;
                }
                 else if(question.QuestionType == 'FreeformText'){
                    question.Answers = [];
                    question.TextType = null;
                    question.LabelType = null;
                    question.RankLimit = 0;
                }
            }

            try {
                let r = await this.tryPost('/api/poll', JSON.stringify(this.editPoll), 'application/json');
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                    this.submitting = false;
                }
                else {
                    r = await this.tryPost(`/api/pollnotify/${this.editPoll.ShowCode}/${this.editPoll.Code}`, JSON.stringify(this.notifyUsers), 'application/json');
                    this.submitting = false;
                
                    this.$emit('saved', this.poll);
                }

            } catch(ex) {
                this.submitting = false;
                alert("Could not save. Network error.");
            }
        },
        onCancel(){
            this.$emit('cancel');
        },
    }

}
</script>
